import { useEffect, useRef, useState } from 'react';
import styles from './App.module.scss';
import Controller from './Controller.js';
import Header from './Header.js'
import Charts from './Charts.js';
import Ask from './Ask.js'
import Bid from './Bid.js'
import Pool from './Pool.js'
import TopBar from './TopBar.js';
import Ticker from './Ticker.js';
import Deals from './Deals.js';
import Orders from './Orders.js';
import Interface from './Interface.js';
import Popup from 'reactjs-popup';
import redCross from './red-cross.png'

function App()
{
    const [sessionActor, setSessionActor] = useState("");
    const [hoverPrice, setHoverPrice] = useState("");
    const [showUnlockModal, setShowUnlockModal] = useState(false);
    const [hasAppContract, setHasAppContract] = useState(false);
    const pwRef = useRef();
    useEffect(() => {
        Controller.setOnUpdateSessionActorCallback(setSessionActor);
        Controller.setSetShowUnlockModal(setShowUnlockModal);
        Controller.setSetHasAppContract(setHasAppContract);
    }, []);

    return (
        <div className={styles["app"]}>
            <header className={styles["app-header"]}>
                <Header sessionActor={sessionActor} />
            </header>
            <div className={styles["app-body"]}>
                <div id={styles["app-container"]}>
                    <div id={styles["top-bar-container"]}>
                        <TopBar />
                    </div>
                    <div id={styles["middle-row-container"]}>
                        <div id={styles["book-pool-container"]}>
                            <div id={styles["book-ask-container"]}>
                                <Ask hoverPrice={hoverPrice} setHoverPrice={setHoverPrice} />
                            </div>
                            <div id={styles["liquidity-pool-container"]}>
                                <Pool hoverPrice={hoverPrice} />
                            </div>
                            <div id={styles["book-bid-container"]}>
                                <Bid hoverPrice={hoverPrice} setHoverPrice={setHoverPrice} />
                            </div>
                        </div>
                        <div id={styles["charts-interface-container"]}>
                            <div id={styles["charts-container"]}>
                                <Charts />
                            </div>
                            <div id={styles["interface-container"]}>
                                <Interface setShowUnlockModal={setShowUnlockModal} hasAppContract={hasAppContract} />
                            </div>
                        </div>
                        <div id={styles["ticker-deals-container"]}>
                            <div id={styles["ticker-container"]}>
                                <Ticker />
                            </div>
                            <div id={styles["deals-container"]}>
                                <Deals />
                            </div>
                        </div>
                    </div>
                    {sessionActor === "" ? <></> :
                        <div id={styles["orders-container"]}>
                            <Orders sessionActor={sessionActor} />
                        </div>
                    }
                </div>
            </div>
            <footer className={styles["app-footer"]}>
                Footer
            </footer>
            <Popup open={showUnlockModal} modal nested>
                <div className='modal'>
                    <div className='header'>
                        <div className='title'>Unlock Stop-Orders</div>
                        <img className='close-icon' src={redCross} onClick={() => setShowUnlockModal(false)} />
                    </div>
                    <div className='content'>
                        <div className='item'>
                            Type in your password to unlock your stop orders:
                        </div>
                        <div className='item'>
                            <div className="input-group">
                                <input autoFocus ref={pwRef} type="password" className="input-field" placeholder="Password" name="unlock-pw" id='unlock-pw' required onKeyDown={(e) => { if(e.key === "Enter") Controller.unlockStopContract(pwRef.current.value) }} />
                                <label for="unlock-pw" className="input-label">Password</label>
                            </div>
                        </div>
                        <div className='item'>
                            In case you forgot your password you can reset the Stop-Contract using the 'Reset' button. The password typed in above will become your new password.
                        </div>
                        <div className='item'>
                            <i>Note that resetting your password will cancel all currently active Stop-Orders!</i>
                        </div>
                        <div className='item'>
                            <button  className='button button-default' onClick={() => Controller.unlockStopContract(pwRef.current.value)}>Unlock</button>
                            <button  className='button button-default' onClick={() => Controller.resetStopContract(pwRef.current.value)}>Reset</button>
                            <button  className='button button-default' onClick={() => setShowUnlockModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    );
}

export default App;
