import { useEffect, useState, useRef } from 'react';
import styles from './Ask.module.scss';
import Controller from './Controller';
import Popup from 'reactjs-popup';

function Ask({ hoverPrice, setHoverPrice })
{
    const [ask, setAsk] = useState({
        rows: [],
        maxAmount: 0,
        priceDecimals: 0,
        basePrecision: 0,
        baseSymbolCode: "BASE",
        quoteSymbolCode: "QUOTE",
    });
    useEffect(() => {
        Controller.setOnUpdateAskCallback(setAsk);
    }, []);
    const [isMouseOver, setIsMouseOver] = useState(false);
    const tbRef = useRef(null);
    useEffect(() => {
        // scroll down ask table after each render (should not happen if mouse is over component)
        if(!isMouseOver)
        {
            tbRef.current.scrollTo(0, tbRef.current.offsetHeight);
        }
    });

    return (
        <div className={styles['table']}>
            <div className={styles['th']}>
                <div className={styles['th-row']}>
                    <div className={styles['th-item']} style={{textAlign: 'center'}}>Ask</div>
                </div>
                <div className={styles['th-row']}>
                    <div className={styles['th-item']} style={{textAlign: 'left'}}>Price ({ask.quoteSymbolCode})</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Amount ({ask.baseSymbolCode})</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Total ({ask.quoteSymbolCode})</div>
                </div>
            </div>
            <div className={styles['tb']} ref={tbRef} onMouseOver={() => setIsMouseOver(true)} onMouseOut={() => setIsMouseOver(false)}>
                <div className={styles['tb-inner']}>
                    {
                        ask.rows.map(row => {
                            let price = row[0].toFixed(ask.priceDecimals);
                            return (
                                <Popup trigger={
                                    <div
                                        className={styles['tb-row']}
                                        style={{backgroundColor: (parseFloat(hoverPrice) >= parseFloat(price) ? '#4C5C8840' : '')}}
                                        onMouseOver={() => setHoverPrice(price)}
                                        onMouseOut={() => setHoverPrice("")}
                                        onClick={() => Controller.setLimitOrder(true, price)}
                                    >
                                        <div className={styles['tb-row-item'] + ' red'} style={{textAlign: 'left'}}>{price}</div>
                                        <div className={styles['tb-row-item']} style={{textAlign: 'right'}}>{row[1].toFixed(ask.basePrecision)}</div>
                                        <div className={styles['tb-row-item']} style={{textAlign: 'right'}}>{(row[1] * row[0]).toFixed(ask.priceDecimals)}</div>
                                        <i className={styles['depth-bar']} style={{width: (row[1] / ask.maxAmount * 100).toFixed(2) + "%"}}></i>
                                    </div>
                                } on={['hover', 'focus']} position="right top" closeOnDocumentClick>
                                    {(() =>  {
                                        // only calculate liquidity if mouse is hovering row and popup is showing because it's expensive!
                                        if(hoverPrice === price)
                                        {
                                            let v = Controller.determineAskLiquidity(price);
                                            return (
                                                <table className={styles['popup']}>
                                                    <tr><td className={styles['th']}>Liquidity</td><td>{v[0]}</td></tr>
                                                    <tr><td className={styles['th']}>Value</td><td>{v[1]}</td></tr>
                                                    <tr><td className={styles['th']}>Avg. Price</td><td>{v[2]}</td></tr>
                                                </table>
                                            );
                                        }
                                        else return <></>;
                                    })()}
                                </Popup>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Ask;