import stopContractWasmHex from './stopcontract.wasm.hex';
import stopContractAbiHex from './stopcontract.abi.hex';
import ecc from 'eosjs-ecc'; // TODO: try to get rid of this dependency and revert these changes: https://stackoverflow.com/a/71280203/2340535
import { Api, JsonRpc } from 'eosjs';
import { JsSignatureProvider } from 'eosjs/dist/eosjs-jssig';
import { createInitialTypes, SerialBuffer } from 'eosjs/dist/eosjs-serialize';
//import { Module } from './dex.js';
var factory = require('./dex.js');
//factory().then((instance) => {
//    let test = instance.cwrap("testWASM", "string")
//    console.log(test())
//});
const wasm = await factory();
const marketBuy = wasm.cwrap("marketBuy", "string", ["string", "string", "string", "string", "string", "string", "number", "number", "number", "number", "number", "number"]);
const marketSell = wasm.cwrap("marketSell", "string", ["string", "string", "string", "string", "string", "string", "number", "number", "number", "number", "number", "number"]);
const determineBuyTotal = wasm.cwrap("determineBuyAmount", "string", ["bool", "string", "string", "string", "string", "string", "string", "number", "number", "number", "number", "number", "number"]);
const determineSellAmount = wasm.cwrap("determineSellAmount", "string", ["bool", "string", "string", "string", "string", "string", "string", "number", "number", "number", "number", "number", "number"]);
const encryptBytes = wasm.cwrap("encryptBytes", "string", ["string", "string"]);
const decryptBytes = wasm.cwrap("decryptBytes", "string", ["string", "string"]);
const encryptOrder = wasm.cwrap("encryptOrder", "string", ["string", "string", "string", "string", "string", "string", "string", "string"]);
const decryptOrder = wasm.cwrap("decryptOrder", "string", ["string", "string"]);
//const test = wasm.cwrap("testWASM", "string");
//console.log(test());

const contractName = '1aa2aa3aa4jd';
var session = undefined;
const apiNode = 'https://test.ultra.eosusa.io';

const HISTORY_SIZE = 100;
var ref_bn = 0;
var ref_ts = 0;
var symbols = [];
var mid = -1;
const markets = new Map();
const history = new Map();
const last_bar = new Map();     // the last bar which was returned by 'getbars'
const tv_subs = new Map();      // tradingview chart subscriptions for realtime updates
var balances = [0, 0, 0];
var trades = [];
var tradesTable = { data: [], curPage: 0, numPages: 0, numTradesPerPage: 50 };
var limit_orders = [];
var stop_orders = [];
var stop_permission = null;     // tuple of { pw, key } of the user's stopcontract
var prices = {};
var ftv_cfg = null;
var ftv_ctx = null;

function convertName(name)
{
    const builtinTypes = createInitialTypes()
    const typeUint64 = builtinTypes.get("uint64")
    const typeName = builtinTypes.get("name")
    var buffer = new SerialBuffer({ textDecoder: new TextDecoder(), textEncoder: new TextEncoder() });
    typeName.serialize(buffer, name)
    return typeUint64.deserialize(buffer)
}
function convertUint64(value)
{
    const builtinTypes = createInitialTypes()
    const typeUint64 = builtinTypes.get("uint64")
    const typeName = builtinTypes.get("name")
    var buffer = new SerialBuffer({ textDecoder: new TextDecoder(), textEncoder: new TextEncoder() });
    typeUint64.serialize(buffer, value)
    return typeName.deserialize(buffer)
}
//console.log(convertName("hossainiiiir"));
//console.log(convertUint64("7868214310977379696"));

function buf2hex(buffer){ return [...new Uint8Array(buffer)].map(x => x.toString(16).padStart(2, '0')).join(''); }
function hex2buf(hex){ let bytes = []; for(let c = 0; c < hex.length; c += 2) bytes.push(parseInt(hex.substr(c, 2), 16)); return bytes; }
function bn2ts(bn) { return ref_ts + (bn - ref_bn) * 500; }
function ts2bn(ts) { return Math.floor(ref_bn + (ts - ref_ts) / 500); }
function base_precision(m) { return parseInt(m.market.base_symbol.substring(0, m.market.base_symbol.indexOf(","))); }
function quote_precision(m) { return parseInt(m.market.quote_symbol.substring(0, m.market.quote_symbol.indexOf(","))); }
function base_symbolcode(m) { return m.market.base_symbol.substring(m.market.base_symbol.indexOf(",") + 1); }
function quote_symbolcode(m) { return m.market.quote_symbol.substring(m.market.quote_symbol.indexOf(",") + 1); }
function market2SharesSymbolCode(market_id)
{
    // share token symbols are in the range from ZLPAAAA to ZLPZZZZ
    let sc = [ 'Z', 'L', 'P', 'A', 'A', 'A', 'A' ];
    let pow1 = 'Z'.charCodeAt() - 'A'.charCodeAt() + 1;
    let pow2 = pow1 * pow1;
    let pow3 = pow1 * pow2;
    let id = market_id;
    let os3 = Math.floor(market_id / pow3);
    id -= os3 * pow3;
    let os2 = Math.floor(id / pow2);
    id -= os2 * pow2;
    let os1 = Math.floor(id / pow1);
    id -= os1 * pow1;
    let os0 = id;
    sc[3] = String.fromCharCode(sc[3].charCodeAt() + os3);
    sc[4] = String.fromCharCode(sc[4].charCodeAt() + os2);
    sc[5] = String.fromCharCode(sc[5].charCodeAt() + os1);
    sc[6] = String.fromCharCode(sc[6].charCodeAt() + os0);
    return sc.join("");
}
const genRandHex = size => [...Array(size)].map(() => Math.floor(Math.random() * 16).toString(16)).join('');
function nextOrderIdHex()
{
    // source: https://stackoverflow.com/questions/58325771/how-to-generate-random-hex-string-in-javascript
    let order_id = genRandHex(4);
    // loop as long as generated order_id already exists in either limit_orders or stop_orders array
    while(limit_orders.filter(o => o.id === order_id).length > 0 || stop_orders.filter(o => o.id === order_id).length > 0)
        order_id = genRandHex(4);
    return order_id;
}
function ftvStatus(ctx, bn)
{
    if(ctx === null)                                                                                    return 6; // nobody logged in                  => show: Fees
    if(ctx.record === null &&                                    (ctx.user_tvl <  ctx.min_tvl))         return 5; // no record, not enough tvl         => show: Fees
    if(ctx.record === null &&                                    (ctx.user_tvl >= ctx.min_tvl))         return 4; // no record, but enough tvl         => show: (y) Free Trading Volume
    if(ctx.record !== null && (bn >  ctx.record.block_height) && (ctx.user_tvl <  ctx.min_tvl))         return 3; // record, expired, not enough tvl   => show: (r) Free Trading Volume
    if(ctx.record !== null && (bn >  ctx.record.block_height) && (ctx.user_tvl >= ctx.min_tvl))         return 2; // record, expired,     enough tvl   => show: (y) Free Trading Volume
    if(ctx.record !== null && (bn <= ctx.record.block_height) && (ctx.record.free_trading_volume <= 0)) return 1; // record, not expired,     consumed => show: (r) Free Trading Volume
    if(ctx.record !== null && (bn <= ctx.record.block_height) && (ctx.record.free_trading_volume >  0)) return 0; // record, not expired, not consumed => show: (g) Free Trading Volume
    return 7;
}

// source: https://stackoverflow.com/a/39914235/2340535
const sleep = ms => new Promise(r => setTimeout(r, ms));
// if the server can't be reached because of, for instance, network issues then fetchJson() will keep looping
// the try/catch until the server becomes reachable again. However, the async sleep() calls in the catch block
// ensure that CPU is yielded after each try to ensure the UI is still responsive to user input in the meawhile.
// This is the desired behaviour.
// source: https://stackoverflow.com/a/13239999/2340535
async function fetchJson(url, body = {})
{
    let retryDelay = 50;
    let maxDelay = 1000;
    let timeout = 10000;
    while(true)
    {
        try
        {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body),
                signal: AbortSignal.timeout(timeout)
            });
            return await response.json();
        }
        catch(err)
        {
            console.error(`Error in fetchJson(${url}): ` , err);
            console.log("Retry in " + retryDelay + "ms");
            await sleep(retryDelay);
            retryDelay = Math.min(retryDelay * 2, maxDelay);
        }
    }
}

async function fetchTrades()
{
    if(!session || markets.size === 0) return;
    let user = session[0];
    let data = await fetchJson('/trades', { user });
    for(let i = 0; i < data.length; i++)
    {
        let m = markets.get(i);
        // t => [t.b, t.v, t.p, t.m, t.t]
        for(let t of data[i])
        {
            trades.push({
                pair: symbols[i].symbol,
                isBuy: (t[0] % 2 === 1),
                lp: (t[3] === '' ? 'Pool' : 'Book'),
                type: (t[3] === user ? 'LIMIT' : (t[4] === user ? 'MARKET' : '')),
                price: (t[2] / Math.pow(10, m.market.price_decimals)).toFixed(m.market.price_decimals) + ' ' + quote_symbolcode(m),
                amount: (t[1] / Math.pow(10, base_precision(m))).toFixed(base_precision(m)) + ' ' + base_symbolcode(m),
                time: new Date(bn2ts(t[0])).toLocaleString('en-US', { hour12: false })
            });
        }
    }
    trades.sort((a, b) => {
        if(b.time > a.time) return -1;
        if(b.time < a.time) return +1;
        return 0;
    });
    tradesTable = {
        data: trades.slice(-tradesTable.numTradesPerPage),
        curPage: 0,
        numPages: Math.ceil(trades.length / tradesTable.numTradesPerPage),
        numTradesPerPage: tradesTable.numTradesPerPage
    };
    onUpdateTradesTableCallback(tradesTable);
}

async function fetchStopOrders()
{
    if(!session || !stop_permission) return;
    stop_orders = [];
    let orders = (await fetchJson('/stoporders', { account_name: session[0] })).orders;
    orders.forEach(o => {
        const aes_key = decryptBytes(o.aes_key_ct, stop_permission.pw);
        const order_pt = JSON.parse(decryptOrder(o.order_ct, aes_key));
        let m = markets.get(order_pt.market_id);
        let isBuy = (order_pt.amount.substring(order_pt.amount.indexOf(" ") + 1) === quote_symbolcode(m));
        let limitPrice = order_pt.limit_price / Math.pow(10, m.market.price_decimals);
        let isLimit = !(limitPrice === 2147483647 || order_pt.limit_price === 0);
        let type;
        if(isBuy && isLimit)  type = "STOP-LIMIT BUY";
        if(isBuy && !isLimit) type = "STOP BUY";
        if(!isBuy && isLimit) type = "STOP-LIMIT SELL";
        if(!isBuy && !isLimit)type = "STOP SELL";
        stop_orders.push({
            id: o.id,
            mid: order_pt.market_id,
            pair: symbols[order_pt.market_id].symbol,
            isBuy,
            type,
            stop_price: (order_pt.stop_price / Math.pow(10, m.market.price_decimals)).toFixed(m.market.price_decimals),
            limit_price: !isLimit ? '-' : limitPrice.toFixed(m.market.price_decimals),
            base_amount_open: isBuy ? '-' : order_pt.amount,    // TODO: isLimit?
            quote_amount_open: isBuy ? order_pt.amount : '-',   // TODO: isLimit?
            fee_earned: '-', // TODO: isLimit?
            base_amount_dealt: "-",
            quote_amount_dealt: "-",
            timestamp: order_pt.timestamp,
            code: order_pt.code,
            aes_key
        });
    });
    onUpdateOrdersCallback((limit_orders.concat(stop_orders)).sort((a, b) => {
        if(b.timestamp < a.timestamp) return -1;
        if(b.timestamp > a.timestamp) return +1;
        return 0;
    }));
}

function findLimitOrders()
{
    if(!session) return;
    limit_orders = [];
    for(let [id, m] of markets)
    {
        m.market.ask.forEach(row => {
            row.forEach(o => {
                if(o.owner === session[0])
                {
                    limit_orders.push({
                        id: o.id,
                        mid: id,
                        pair: symbols[id].symbol,
                        isBuy: false,
                        type: "LIMIT SELL",
                        stop_price: "-",
                        limit_price: (o.price / Math.pow(10, m.market.price_decimals)).toFixed(m.market.price_decimals),
                        base_amount_open: (o.amount_open / Math.pow(10, base_precision(m))).toFixed(base_precision(m)) + " " + base_symbolcode(m),
                        quote_amount_open: ((1.0 + m.maker_fee) * (o.amount_open / Math.pow(10, base_precision(m))) * (o.price / Math.pow(10, m.market.price_decimals))).toFixed(quote_precision(m)) + " " + quote_symbolcode(m),
                        base_initial_amount_open: ((o.amount_open / Math.pow(10, base_precision(m))) + (1.0 - m.maker_fee) * (o.amount_dealt / Math.pow(10, quote_precision(m))) / (o.price / Math.pow(10, m.market.price_decimals))).toFixed(base_precision(m)) + " " + base_symbolcode(m),
                        quote_initial_amount_open: ((1.0 + m.maker_fee) * (o.amount_open / Math.pow(10, base_precision(m))) * (o.price / Math.pow(10, m.market.price_decimals)) + (o.amount_dealt / Math.pow(10, quote_precision(m)))).toFixed(quote_precision(m)) + " " + quote_symbolcode(m),
                        progress: (o.amount_dealt / Math.pow(10, quote_precision(m))) / ((1.0 + m.maker_fee) * (o.amount_open / Math.pow(10, base_precision(m))) * (o.price / Math.pow(10, m.market.price_decimals)) + (o.amount_dealt / Math.pow(10, quote_precision(m)))) * 100.0,
                        fee_earned: (m.maker_fee * ((1.0 + m.maker_fee) * (o.amount_open / Math.pow(10, base_precision(m))) * (o.price / Math.pow(10, m.market.price_decimals)) + (o.amount_dealt / Math.pow(10, quote_precision(m))))).toFixed(quote_precision(m)) + " " + quote_symbolcode(m),
                        base_amount_dealt: ((o.amount_dealt / Math.pow(10, quote_precision(m))) / (o.price / Math.pow(10, m.market.price_decimals))).toFixed(base_precision(m)) + " " + base_symbolcode(m),
                        quote_amount_dealt: (o.amount_dealt / Math.pow(10, quote_precision(m))).toFixed(quote_precision(m)) + " " + quote_symbolcode(m),
                        timestamp: bn2ts(o.block_num)
                    });
                }
            });
        });
        m.market.bid.forEach(row => {
            row.forEach(o => {
                if(o.owner === session[0])
                {
                    limit_orders.push({
                        id: o.id,
                        mid: id,
                        pair: symbols[id].symbol,
                        isBuy: true,
                        type: "LIMIT BUY",
                        stop_price: "-",
                        limit_price: (o.price / Math.pow(10, m.market.price_decimals)).toFixed(m.market.price_decimals),
                        base_amount_open: ((1.0 + m.maker_fee) * (o.amount_open / Math.pow(10, quote_precision(m))) / (o.price / Math.pow(10, m.market.price_decimals))).toFixed(base_precision(m)) + " " + base_symbolcode(m),
                        quote_amount_open: (o.amount_open / Math.pow(10, quote_precision(m))).toFixed(quote_precision(m)) + " " + quote_symbolcode(m),
                        base_initial_amount_open: ((1.0 + m.maker_fee) * (o.amount_open / Math.pow(10, quote_precision(m))) / (o.price / Math.pow(10, m.market.price_decimals)) + (o.amount_dealt / Math.pow(10, base_precision(m)))).toFixed(base_precision(m)) + " " + base_symbolcode(m),
                        quote_initial_amount_open: ((o.amount_open / Math.pow(10, quote_precision(m))) + (1.0 - m.maker_fee) * (o.amount_dealt / Math.pow(10, base_precision(m))) * (o.price / Math.pow(10, m.market.price_decimals))).toFixed(quote_precision(m)) + " " + quote_symbolcode(m),
                        progress: (o.amount_dealt / Math.pow(10, base_precision(m))) / ((1.0 + m.maker_fee) * (o.amount_open / Math.pow(10, quote_precision(m))) / (o.price / Math.pow(10, m.market.price_decimals)) + (o.amount_dealt / Math.pow(10, base_precision(m)))) * 100.0,
                        fee_earned: (m.maker_fee * ((o.amount_open / Math.pow(10, quote_precision(m))) + (1.0 - m.maker_fee) * (o.amount_dealt / Math.pow(10, base_precision(m))) * (o.price / Math.pow(10, m.market.price_decimals)))).toFixed(quote_precision(m)) + " " + quote_symbolcode(m),
                        base_amount_dealt: (o.amount_dealt / Math.pow(10, base_precision(m))).toFixed(base_precision(m)) + " " + base_symbolcode(m),
                        quote_amount_dealt: ((o.amount_dealt / Math.pow(10, base_precision(m))) * (o.price / Math.pow(10, m.market.price_decimals))).toFixed(quote_precision(m)) + " " + quote_symbolcode(m),
                        timestamp: bn2ts(o.block_num)
                    });
                }
            });
        });
    }
    onUpdateOrdersCallback((limit_orders.concat(stop_orders)).sort((a, b) => {
        if(b.timestamp < a.timestamp) return -1;
        if(b.timestamp > a.timestamp) return +1;
        return 0;
    }));
}

async function fetchMarkets(ids)
{
    var startTime = performance.now();

    let tuples = [];
    for(const id of ids)
    {
        let bn = 0;
        if(history.has(id))
        {
            let h = history.get(id);
            if(h.length > 0)
            {
                bn = h[h.length - 1][0];
            }
        }
        tuples.push({ id, bn });
    }

    let res = await fetchJson('/markets', { tuples });
    ref_bn = res.ref_bn;
    ref_ts = res.ref_ts;
    onUpdateRefBlockCallback({bn: ref_bn, ts: ref_ts});
    onUpdateTickerCallback(res.ticker);
    for(const m of res.markets)
    {
        let h = history.has(m.id) ? history.get(m.id) : [];
        // trigger stop orders for this market
        triggerStopOrders(m.id, [...m.market.history.map(t => (t[2] / Math.pow(10, m.market.price_decimals)).toFixed(m.market.price_decimals)), parseFloat(((m.market.amount_quote / Math.pow(10, quote_precision(m))) / (m.market.amount_base / Math.pow(10, base_precision(m)))).toFixed(m.market.price_decimals))]);
        // update trade history (if logged in)
        if(session)
        {
            let user = session[0];
            m.market.history.forEach(t => {
                if(t[3] === user || t[4] === user)
                {
                    trades.push({
                        pair: symbols[m.id].symbol,
                        isBuy: (t[0] % 2 === 1),
                        lp: (t[3] === '' ? 'Pool' : 'Book'),
                        type: (t[3] === user ? 'LIMIT' : (t[4] === user ? 'MARKET' : '')),
                        price: (t[2] / Math.pow(10, m.market.price_decimals)).toFixed(m.market.price_decimals) + ' ' + quote_symbolcode(m),
                        amount: (t[1] / Math.pow(10, base_precision(m))).toFixed(base_precision(m)) + ' ' + base_symbolcode(m),
                        time: new Date(bn2ts(t[0])).toLocaleString('en-US', { hour12: false })
                    });
                }
            });
            let iStart = trades.length - (tradesTable.curPage+1) * tradesTable.numTradesPerPage;
            let iEnd = trades.length - tradesTable.curPage * tradesTable.numTradesPerPage;
            tradesTable = {
                data: trades.slice(iStart < 0 ? 0 : iStart, iEnd > trades.length ? trades.length : iEnd),
                curPage: tradesTable.curPage,
                numPages: Math.ceil(trades.length / tradesTable.numTradesPerPage),
                numTradesPerPage: tradesTable.numTradesPerPage
            };
            onUpdateTradesTableCallback(tradesTable);
        }
        // update all related subscriptions with new tick values of that market
        tv_subs.forEach((sub, key) =>
        {
            if(sub.symbolInfo.full_name === symbols[m.id].full_name)
            {
                let period = sub.resolution;
                if     (period === "1D") period = 1440 * 60 * 1000;  // miliseconds per day
                else if(period === "1W") period = 10080 * 60 * 1000; // miliseconds per week
                else if(period === "1M") return;                     // live updates for monthly charts not supported
                else                     period *= 60 * 1000;        // convert minutes to miliseconds

                // reset is required if the next incoming tick is older than the current candlestick opening time
                if(m.market.history.length > 0 && bn2ts(m.market.history[0][0]) < sub.lastBar.time)
                {
                    sub.reset();
                    window.tvWidget.chart().resetData();
                }
                else
                {
                    // feed all new ticks into chart
                    let i = 0;
                    while(i < m.market.history.length)
                    {
                        // check if this tick starts a new period
                        if(bn2ts(m.market.history[i][0]) >= (sub.lastBar.time + period))
                        {
                            let newBar = {
                                time: sub.lastBar.time + period,
                                open: sub.lastBar.close,
                                high: sub.lastBar.close,
                                low: sub.lastBar.close,
                                close: m.market.history[i][2] / Math.pow(10, m.market.price_decimals),
                                volume: m.market.history[i][1] / Math.pow(10, base_precision(m)),
                            }
                            sub.lastBar = newBar;
                        }
                        else
                        {
                            // update last bar
                            if(m.market.history[i][2] / Math.pow(10, m.market.price_decimals) < sub.lastBar.low)
                            {
                                sub.lastBar.low = m.market.history[i][2] / Math.pow(10, m.market.price_decimals);
                            }
                            else if(m.market.history[i][2] / Math.pow(10, m.market.price_decimals) > sub.lastBar.high)
                            {
                                sub.lastBar.high = m.market.history[i][2] / Math.pow(10, m.market.price_decimals);
                            }
                            sub.lastBar.volume += m.market.history[i][1] / Math.pow(10, base_precision(m));
                            sub.lastBar.close = m.market.history[i][2] / Math.pow(10, m.market.price_decimals);
                        }
                        sub.update(sub.lastBar);
                        i++;
                    }
                    // check if we need to start a new period without new ticks (i.e. add an empty bar)
                    if(i == m.market.history.length && Date.now() >= (sub.lastBar.time + period))
                    {
                        let newBar = {
                            time: sub.lastBar.time + period,
                            open: sub.lastBar.close,
                            high: sub.lastBar.close,
                            low: sub.lastBar.close,
                            close: sub.lastBar.close,
                            volume: 0,
                        }
                        sub.update(newBar);
                        sub.lastBar = newBar;
                    }
                }
            }
        });
        // update most recent market history
        if(m.market.history.length >= h.length)
        {
            h = m.market.history;
        }
        else
        {
            h = h.splice(m.market.history.length).concat(m.market.history);
        }
        history.set(m.id, h); // TODO: necessary?
        delete m.market.history;
        markets.set(m.id, m);
    }

    var endTime = performance.now();
    //console.log(`'fetchMarkets()' execution time: ${endTime - startTime} ms`);
}

// DatafeedConfiguration implementation
const configurationData = {
    // Represents the resolutions for bars supported by your datafeed
    supported_resolutions: ['1', '5', '15', '30', '60', '240', '1D', '1W', '1M'],
    // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
    exchanges: [{ value: 'ZDEX', name: 'ZDEX', desc: 'Ultra ZDEX'}],
    // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
    symbols_types: [{ name: 'crypto', value: 'crypto'}]
};

var onUpdateSessionActor = function() {}
var onUpdateAskCallback = function() {}
var onUpdatePoolCallback = function() {}
var onUpdateBidCallback = function() {}
var onUpdateDealsCallback = function() {}
var onUpdateTopBarCallback = function() {}
var onUpdateTickerCallback = function() {}
var onUpdateInterfaceCallback = function() {}
var onUpdateBalancesCallback = function() {}
var onUpdateFtvCtxCallback = function() {}
var onUpdateRefBlockCallback = function() {}
var onUpdateOrdersCallback = function() {}
var onUpdateTradesTableCallback = function() {}
var setLimitOrder = function() {}
var setHasAppContract = function() {}
var setUnlocked = function() {}
var setShowUnlockModal = function() {}

async function didLogin()
{
    console.log("did login...")
    if(!session) return;
    onUpdateSessionActor(session[0]);
    balances = await fetchJson('/balances', { user: session[0], mid });
    onUpdateBalancesCallback(balances);
    ftv_ctx = await fetchJson('/ftvctx', { user: session[0], shares_amount: ftv_cfg.shares_mid === mid ? balances[2] : undefined });
    onUpdateFtvCtxCallback(ftv_ctx);
    setUnlocked(false);
    findLimitOrders();
    fetchTrades();
    let codehash = (await fetchJson('/codehash', { account_name: session[0] })).codehash;
    console.log("current code hash: " + codehash);
    let hasAppContract = codehash === "97f60ee25227d1752b910a7732564eba94259f9c934fb9fe083357cfd3fd6dbe";
    setHasAppContract(hasAppContract);
    if(hasAppContract)
    {
        setShowUnlockModal(true);
    }
}

function triggerStopOrders(mid, history)
{
    if(!session || !stop_permission) return;
    stop_orders.filter(o => o.mid === mid).forEach(async o => {
        for(const h of history)
        {
            if((o.isBuy && (parseFloat(h) >= parseFloat(o.stop_price))) || (!o.isBuy && (parseFloat(h) <= parseFloat(o.stop_price))))
            {
                const actions = [{
                    account: session[0],
                    name: 'executeorder',
                    authorization: [{
                        actor: session[0],
                        permission: 'zeosexecstop',
                    }],
                    data: {
                        recipient: contractName,
                        id: o.id,
                        aes_key: o.aes_key
                    },
                }];
                const signatureProvider = new JsSignatureProvider([stop_permission.key]);
                const rpc = new JsonRpc(apiNode, { fetch });
                const api = new Api({ rpc, signatureProvider, chainId: (await ultra.getChainId()).data, textDecoder: null, textEncoder: null });
                api.transact({ actions }, { blocksBehind: 3, expireSeconds: 30 }).then((result) => {
                    //console.log(result);
                    fetchStopOrders();
                });
            }
        }
    });
}

async function updateMarketsCronjob()
{
    var startTime = performance.now();

    let ids;
    if(-1 === mid)
    {
        symbols = await fetchJson('/symbols');
        if(symbols.length > 0)
        {
            mid = 0;
            ids = [...symbols.keys()];
        }
    }
    else
    {
        // determine all markets we need to fetch: All markets on which
        // we either have a limit or a stop-order open, or the currently selcted market
        ids = [ mid, ...limit_orders.map(o => o.mid), ...stop_orders.map(o => o.mid) ];
        ids = ids.filter(function(mid, pos) { return ids.indexOf(mid) === pos }); // filter out duplicates
    }
    //await fetchMarkets(market_ids);
    let tuples = [];
    for(const id of ids)
    {
        let bn = 0;
        if(history.has(id))
        {
            let h = history.get(id);
            if(h.length > 0)
            {
                bn = h[h.length - 1][0];
            }
        }
        tuples.push({ id, bn });
    }

    let res = await fetchJson('/markets', { tuples });
    ref_bn = res.ref_bn;
    ref_ts = res.ref_ts;
    prices = res.prices;
    onUpdateRefBlockCallback({bn: ref_bn, ts: ref_ts});
    onUpdateTickerCallback(res.ticker);
    for(const m of res.markets)
    {
        let h = history.has(m.id) ? history.get(m.id) : [];
        // trigger stop orders for this market
        triggerStopOrders(m.id, [...m.market.history.map(t => (t[2] / Math.pow(10, m.market.price_decimals)).toFixed(m.market.price_decimals)), parseFloat(((m.market.amount_quote / Math.pow(10, quote_precision(m))) / (m.market.amount_base / Math.pow(10, base_precision(m)))).toFixed(m.market.price_decimals))]);
        // update trade history (if logged in)
        if(session)
        {
            let s = ftvStatus(ftv_ctx, ts2bn(Date.now()));
            let updFtv = false;
            let user = session[0];
            m.market.history.forEach(t => {
                if(t[3] === user || t[4] === user)
                {
                    trades.push({
                        pair: symbols[m.id].symbol,
                        isBuy: (t[0] % 2 === 1),
                        lp: (t[3] === '' ? 'Pool' : 'Book'),
                        type: (t[3] === user ? 'LIMIT' : (t[4] === user ? 'MARKET' : '')),
                        price: (t[2] / Math.pow(10, m.market.price_decimals)).toFixed(m.market.price_decimals) + ' ' + quote_symbolcode(m),
                        amount: (t[1] / Math.pow(10, base_precision(m))).toFixed(base_precision(m)) + ' ' + base_symbolcode(m),
                        time: new Date(bn2ts(t[0])).toLocaleString('en-US', { hour12: false })
                    });
                    if(s === 0 || s === 2 || s === 4) updFtv = true;
                }
            });
            let iStart = trades.length - (tradesTable.curPage+1) * tradesTable.numTradesPerPage;
            let iEnd = trades.length - tradesTable.curPage * tradesTable.numTradesPerPage;
            tradesTable = {
                data: trades.slice(iStart < 0 ? 0 : iStart, iEnd > trades.length ? trades.length : iEnd),
                curPage: tradesTable.curPage,
                numPages: Math.ceil(trades.length / tradesTable.numTradesPerPage),
                numTradesPerPage: tradesTable.numTradesPerPage
            };
            onUpdateTradesTableCallback(tradesTable);
            if(updFtv)
            {
                ftv_ctx = await fetchJson('/ftvctx', { user: session[0], shares_amount: ftv_ctx.shares_amount });
                onUpdateFtvCtxCallback(ftv_ctx);
            }
        }
        // update all related subscriptions with new tick values of that market
        tv_subs.forEach((sub, key) =>
        {
            if(sub.symbolInfo.full_name === symbols[m.id].full_name)
            {
                let period = sub.resolution;
                if     (period === "1D") period = 1440 * 60 * 1000;  // miliseconds per day
                else if(period === "1W") period = 10080 * 60 * 1000; // miliseconds per week
                else if(period === "1M") return;                     // live updates for monthly charts not supported
                else                     period *= 60 * 1000;        // convert minutes to miliseconds

                // reset is required if either: the next incoming tick is older than the current candlestick opening time (eg.: server had network issues and caught up with blockchain)
                // or: the current time is more than two periods in the future of the curren bar which means we missed at least one bar (eg.: client had network issues and caught up with server)
                if((m.market.history.length > 0 && bn2ts(m.market.history[0][0]) < sub.lastBar.time) || (Date.now() >= (sub.lastBar.time + 2 * period)))
                {
                    sub.reset();
                    window.tvWidget.chart().resetData();
                }
                else
                {
                    // feed all new ticks into chart
                    let i = 0;
                    while(i < m.market.history.length)
                    {
                        // check if this tick starts a new period
                        if(bn2ts(m.market.history[i][0]) >= (sub.lastBar.time + period))
                        {
                            let newBar = {
                                time: sub.lastBar.time + period,
                                open: sub.lastBar.close,
                                high: sub.lastBar.close,
                                low: sub.lastBar.close,
                                close: m.market.history[i][2] / Math.pow(10, m.market.price_decimals),
                                volume: m.market.history[i][1] / Math.pow(10, base_precision(m)),
                            }
                            sub.lastBar = newBar;
                        }
                        else
                        {
                            // update last bar
                            if(m.market.history[i][2] / Math.pow(10, m.market.price_decimals) < sub.lastBar.low)
                            {
                                sub.lastBar.low = m.market.history[i][2] / Math.pow(10, m.market.price_decimals);
                            }
                            else if(m.market.history[i][2] / Math.pow(10, m.market.price_decimals) > sub.lastBar.high)
                            {
                                sub.lastBar.high = m.market.history[i][2] / Math.pow(10, m.market.price_decimals);
                            }
                            sub.lastBar.volume += m.market.history[i][1] / Math.pow(10, base_precision(m));
                            sub.lastBar.close = m.market.history[i][2] / Math.pow(10, m.market.price_decimals);
                        }
                        sub.update(sub.lastBar);
                        i++;
                    }
                    // check if we need to start a new period without new ticks (i.e. add an empty bar)
                    if(i == m.market.history.length && Date.now() >= (sub.lastBar.time + period))
                    {
                        let newBar = {
                            time: sub.lastBar.time + period,
                            open: sub.lastBar.close,
                            high: sub.lastBar.close,
                            low: sub.lastBar.close,
                            close: sub.lastBar.close,
                            volume: 0,
                        }
                        sub.update(newBar);
                        sub.lastBar = newBar;
                    }
                }
            }
        });
        // update most recent market history
        if(m.market.history.length >= h.length)
        {
            h = m.market.history;
        }
        else
        {
            h = h.splice(m.market.history.length).concat(m.market.history);
        }
        history.set(m.id, h);
        delete m.market.history;
        markets.set(m.id, m);
    }
    findLimitOrders();
    let m = markets.get(mid);
    let h = history.get(mid);

    // update latest deals
    let deals = [];
    for(let i = h.length-1; i >= Math.max(0, h.length-1 - HISTORY_SIZE); i--)
    {
        deals.push([
            ((h[i][0] % 2) === 1),
            ((h[i][3] === "") ? "Pool" : "Book"),
            (h[i][2] / Math.pow(10, m.market.price_decimals)).toFixed(m.market.price_decimals),
            (h[i][1] / Math.pow(10, base_precision(m))).toFixed(base_precision(m)),
            bn2ts(h[i][0])
        ]);
    }
    onUpdateDealsCallback({
        rows: deals,
        baseSymbolCode: base_symbolcode(m),
        quoteSymbolCode: quote_symbolcode(m),
    });

    // update order book
    // determine available pool liquidity (this is the liquidity between current pool price and first bid/ask row)
    let pool_ask_lq = m.market.amount_base / Math.pow(10, base_precision(m));
    let pool_bid_lq = m.market.amount_quote / Math.pow(10, quote_precision(m));
    let x_ = m.market.amount_quote / Math.pow(10, quote_precision(m));
    let y_ = m.market.amount_base / Math.pow(10, base_precision(m));
    let p_, dx, dy;
    if(m.market.ask.length > 0)
    {
        p_ = m.market.ask[0][0].price / Math.pow(10, m.market.price_decimals);
        dx = (-x_ + Math.sqrt(p_ * y_ * x_));
        dy = (dx * y_) / (x_ + dx);
        pool_ask_lq = dy;
    }
    if(m.market.bid.length > 0)
    {
        p_ = m.market.bid[0][0].price / Math.pow(10, m.market.price_decimals);
        dy = (-y_ + Math.sqrt((x_ * y_) / p_));
        dx = (dy * x_) / (y_ + dy);
        pool_bid_lq = dx;
    }
    let ask = [];
    let bid = [];
    // find ask & bid rows with highest amounts to determine length of liquidity depth bars (initialize with pool liquidity values)
    let max_ask_amt = pool_ask_lq;
    m.market.ask.forEach(row => {
        let sum = row.reduce((sum, o) => sum + o.amount_open, 0);
        sum /= Math.pow(10, base_precision(m));
        ask.unshift([row[0].price / Math.pow(10, m.market.price_decimals), sum]);
        max_ask_amt = Math.max(max_ask_amt, sum);
    })
    let max_bid_amt = pool_bid_lq;
    m.market.bid.forEach(row => {
        let sum = row.reduce((sum, o) => sum + o.amount_open, 0);
        sum /=  Math.pow(10, quote_precision(m));
        bid.push([row[0].price / Math.pow(10, m.market.price_decimals), sum]);
        max_bid_amt = Math.max(max_bid_amt, sum);
    })
    // calculate price
    let p = (m.market.amount_quote / Math.pow(10, quote_precision(m))) / (m.market.amount_base / Math.pow(10, base_precision(m)));
    let price = p.toFixed(m.market.price_decimals);
    let price_usd = (p * prices[m.market.quote_symbol + '@' + m.market.quote_symbol_contract]).toFixed(2);
    onUpdateAskCallback({
        rows: ask,
        maxAmount: max_ask_amt,
        priceDecimals: m.market.price_decimals,
        basePrecision: base_precision(m),
        baseSymbolCode: base_symbolcode(m),
        quoteSymbolCode: quote_symbolcode(m),
    });
    onUpdateBidCallback({
        rows: bid,
        maxAmount: max_bid_amt,
        priceDecimals: m.market.price_decimals,
        quotePrecision: quote_precision(m),
        baseSymbolCode: base_symbolcode(m),
        quoteSymbolCode: quote_symbolcode(m),
    });
    onUpdatePoolCallback({
        price,
        price_usd,
        base: m.market.amount_base / Math.pow(10, base_precision(m)),
        baseSymbolCode: base_symbolcode(m),
        depthBarAsk: (pool_ask_lq / max_ask_amt * 100).toFixed(2) + '%',
        quote: m.market.amount_quote / Math.pow(10, quote_precision(m)),
        quoteSymbolCode: quote_symbolcode(m),
        depthBarBid: (pool_bid_lq / max_bid_amt * 100).toFixed(2) + '%',
        green: deals.length > 0 ? deals[0][0] : true
    });
    onUpdateTopBarCallback({
        price,
        price_usd,
        green: deals.length > 0 ? deals[0][0] : true,
        change: (m.last24hBar.close / m.last24hBar.open - 1) * 100,
        high: m.last24hBar.high.toFixed(m.market.price_decimals),
        low: m.last24hBar.low.toFixed(m.market.price_decimals),
        volume: m.last24hBar.volume.toFixed(base_precision(m)),
        baseSymbolCode: base_symbolcode(m),
        quoteSymbolCode: quote_symbolcode(m),
    });
    onUpdateInterfaceCallback({
        baseSymbolCode: base_symbolcode(m),
        quoteSymbolCode: quote_symbolcode(m),
        sharesSymbolCode: market2SharesSymbolCode(mid),
        baseAmount: m.market.amount_base,
        quoteAmount: m.market.amount_quote,
        sharesAmount: m.market.amount_shares,
        basePrecision: base_precision(m),
        quotePrecision: quote_precision(m),
        priceDecimals: m.market.price_decimals,
        platform_fee_pool: m.platform_fee_pool,
        maker_fee_pool: m.maker_fee_pool,
        platform_fee_book: m.platform_fee_book,
        maker_fee_book: m.maker_fee_book,
        base_price_usd: prices[m.market.base_symbol + '@' + m.market.base_symbol_contract],
        quote_price_usd: prices[m.market.quote_symbol + '@' + m.market.quote_symbol_contract],
        ftv_lp_mid: ftv_cfg.shares_mid,
        ftv_lp_market: base_symbolcode(markets.get(ftv_cfg.shares_mid)) + '/' + quote_symbolcode(markets.get(ftv_cfg.shares_mid))
    });

    var endTime = performance.now();
    if(endTime - startTime > 500) console.log(`'updateMarkets()' execution time: ${endTime - startTime} ms`);
    setTimeout(updateMarketsCronjob, Math.max(50, Math.floor(500 - (endTime - startTime))));
}

export default {
    setOnUpdateSessionActorCallback: (callback) => { onUpdateSessionActor = callback },
    setOnUpdateAskCallback: (callback) => { onUpdateAskCallback = callback },
    setOnUpdatePoolCallback: (callback) => { onUpdatePoolCallback = callback },
    setOnUpdateBidCallback: (callback) => { onUpdateBidCallback = callback },
    setOnUpdateDealsCallback: (callback) => { onUpdateDealsCallback = callback },
    setOnUpdateTopBarCallback: (callback) => { onUpdateTopBarCallback = callback },
    setOnUpdateTickerCallback: (callback) => { onUpdateTickerCallback = callback },
    setOnUpdateInterfaceCallback: (callback) => { onUpdateInterfaceCallback = callback },
    setOnUpdateBalancesCallback: (callback) => { onUpdateBalancesCallback = callback },
    setOnUpdateFtvCtxCallback: (callback) => { onUpdateFtvCtxCallback = callback },
    setOnUpdateRefBlockCallback: (callback) => { onUpdateRefBlockCallback = callback },
    setOnUpdateOrdersCallback: (callback) => { onUpdateOrdersCallback = callback },
    setOnUpdateTradesTableCallback: (callback) => { onUpdateTradesTableCallback = callback },
    setLimitOrder: (isBuy, price) => { setLimitOrder(isBuy, price) },
    setSetLimitOrder: (fn) => { setLimitOrder = fn },
    setSetHasAppContract: (fn) => { setHasAppContract = fn },
    setSetUnlocked: (fn) => { setUnlocked = fn },
    setSetShowUnlockModal: (fn) => { setShowUnlockModal = fn },

    marketBuyPreview: function(order, ftvQuote = 0.0)
    {
        if(mid === -1) return "0";
        let m = markets.get(mid);
        let ftvQuoteAmount = ftvQuote * Math.pow(10, quote_precision(m));
        let o_res = JSON.parse(marketBuy(m.market.base_symbol, m.market.quote_symbol, JSON.stringify(m.market.ask), order, m.market.amount_base.toString(), m.market.amount_quote.toString(), m.market.price_decimals, m.platform_fee_pool, m.maker_fee_pool, m.platform_fee_book, m.maker_fee_book, ftvQuoteAmount));
        let amount = (parseInt(o_res.amount_dealt) / Math.pow(10, base_precision(m))).toFixed(base_precision(m));
        return amount;
    },
    marketSellPreview: function(order, ftvBase = 0.0)
    {
        if(mid === -1) return "0";
        let m = markets.get(mid);
        let ftvBaseAmount = ftvBase * Math.pow(10, base_precision(m));
        let o_res = JSON.parse(marketSell(m.market.base_symbol, m.market.quote_symbol, JSON.stringify(m.market.bid), order, m.market.amount_base.toString(), m.market.amount_quote.toString(), m.market.price_decimals, m.platform_fee_pool, m.maker_fee_pool, m.platform_fee_book, m.maker_fee_book, ftvBaseAmount));
        let total = (parseInt(o_res.amount_dealt) / Math.pow(10, quote_precision(m))).toFixed(quote_precision(m));
        return total;
    },
    determineBuyTotal: function(price, ftvQuote = 0.0)
    {
        if(mid === -1) return [0, 0, 0];
        let m = markets.get(mid);
        let ftvQuoteAmount = ftvQuote * Math.pow(10, quote_precision(m));
        let total = determineBuyTotal(true, m.market.base_symbol, m.market.quote_symbol, JSON.stringify(m.market.ask), Math.round(price * Math.pow(10, m.market.price_decimals)).toString(), m.market.amount_base.toString(), m.market.amount_quote.toString(), m.market.price_decimals, m.platform_fee_pool, m.maker_fee_pool, m.platform_fee_book, m.maker_fee_book, ftvQuoteAmount);
        total = parseFloat(total) / Math.pow(10, quote_precision(m));
        return [
            (total / price).toFixed(base_precision(m)),
            total / balances[1] * 100.0,
            total.toFixed(quote_precision(m))
        ];
    },
    determineSellAmount: function(price, ftvBase = 0.0)
    {
        if(mid === -1) return [0, 0, 0];
        let m = markets.get(mid);
        let ftvBaseAmount = ftvBase * Math.pow(10, base_precision(m));
        let amount = determineSellAmount(true, m.market.base_symbol, m.market.quote_symbol, JSON.stringify(m.market.bid), Math.round(price * Math.pow(10, m.market.price_decimals)).toString(), m.market.amount_base.toString(), m.market.amount_quote.toString(), m.market.price_decimals, m.platform_fee_pool, m.maker_fee_pool, m.platform_fee_book, m.maker_fee_book, ftvBaseAmount);
        amount = parseFloat(amount) / Math.pow(10, base_precision(m));
        return [
            amount.toFixed(base_precision(m)),
            amount / balances[0] * 100.0,
            (amount * price).toFixed(quote_precision(m)),
        ];
    },
    determineAskLiquidity: function(price)
    {
        if(mid === -1) return [0, 0, 0];
        let m = markets.get(mid);
        let total = determineBuyTotal(true, m.market.base_symbol, m.market.quote_symbol, JSON.stringify(m.market.ask), Math.round(price * Math.pow(10, m.market.price_decimals)).toString(), m.market.amount_base.toString(), m.market.amount_quote.toString(), m.market.price_decimals, m.platform_fee_pool, m.maker_fee_pool, m.platform_fee_book, m.maker_fee_book, Number.MAX_VALUE);
        let order = "{\"amount_open\":" + total + ",\"amount_dealt\":0,\"price\":2147483647,\"owner\":\"owner\",\"id\":\"id\",\"block_num\":0}";
        let o_res = JSON.parse(marketBuy(m.market.base_symbol, m.market.quote_symbol, JSON.stringify(m.market.ask), order, m.market.amount_base.toString(), m.market.amount_quote.toString(), m.market.price_decimals, m.platform_fee_pool, m.maker_fee_pool, m.platform_fee_book, m.maker_fee_book, Number.MAX_VALUE));
        let amount = (parseFloat(o_res.amount_dealt) / Math.pow(10, base_precision(m))).toFixed(base_precision(m));
        total = (parseFloat(total) / Math.pow(10, quote_precision(m))).toFixed(quote_precision(m));
        return [
            amount + " " + base_symbolcode(m),
            total + " " + quote_symbolcode(m),
            (total / amount).toFixed(m.market.price_decimals) + " " + quote_symbolcode(m)
        ];
    },
    determineBidLiquidity: function(price)
    {
        if(mid === -1) return [0, 0, 0];
        let m = markets.get(mid);
        let amount = determineSellAmount(true, m.market.base_symbol, m.market.quote_symbol, JSON.stringify(m.market.bid), Math.round(price * Math.pow(10, m.market.price_decimals)).toString(), m.market.amount_base.toString(), m.market.amount_quote.toString(), m.market.price_decimals, m.platform_fee_pool, m.maker_fee_pool, m.platform_fee_book, m.maker_fee_book, Number.MAX_VALUE);
        let order = "{\"amount_open\":" + amount + ",\"amount_dealt\":0,\"price\":0,\"owner\":\"owner\",\"id\":\"id\",\"block_num\":0}";
        let o_res = JSON.parse(marketSell(m.market.base_symbol, m.market.quote_symbol, JSON.stringify(m.market.bid), order, m.market.amount_base.toString(), m.market.amount_quote.toString(), m.market.price_decimals, m.platform_fee_pool, m.maker_fee_pool, m.platform_fee_book, m.maker_fee_book, Number.MAX_VALUE));
        let total = (parseFloat(o_res.amount_dealt) / Math.pow(10, quote_precision(m))).toFixed(quote_precision(m));
        amount = (parseFloat(amount) / Math.pow(10, base_precision(m))).toFixed(base_precision(m));
        return [
            total + " " + quote_symbolcode(m),
            amount + " " + base_symbolcode(m),
            (total / amount).toFixed(m.market.price_decimals) + " " + quote_symbolcode(m)
        ];
    },

    setCurTradesTablePage: function(num)
    {
        if(num < 0 || num >= tradesTable.numPages) return;
        let iStart = trades.length - (num+1) * tradesTable.numTradesPerPage;
        let iEnd = trades.length - num * tradesTable.numTradesPerPage;
        tradesTable = {
            data: trades.slice(iStart < 0 ? 0 : iStart, iEnd > trades.length ? trades.length : iEnd),
            curPage: num,
            numPages: Math.ceil(trades.length / tradesTable.numTradesPerPage),
            numTradesPerPage: tradesTable.numTradesPerPage
        };
        onUpdateTradesTableCallback(tradesTable);
    },

    init: async function()
    {
        console.log("initialize Controller...");
        ftv_cfg = await fetchJson('/ftvcfg');
        await updateMarketsCronjob();
    },
    login: async function()
    {
        if('ultra' in window)
        {
            ultra.connect().then((res) => {
                if(res.status === "success")
                {
                    // session[0] -> actor, session[1] -> permission
                    session = res.data.blockchainid.split('@');
                    didLogin();
                }
                else
                {
                    console.log(res.message);
                }
            });
        }
    },
    logout: async function()
    {
        let res = await ultra.disconnect();
        if(res.status === "success" && res.data)
        {
            console.log("logout...");
            session = undefined;
            onUpdateSessionActor("");
            balances = [0, 0, 0];
            onUpdateBalancesCallback(balances);
            onUpdateFtvCtxCallback(null);
            trades = [];
            tradesTable = { data: [], curPage: 0, numPages: 0, numTradesPerPage: 50 };
            onUpdateTradesTableCallback(tradesTable);
            limit_orders = [];
            stop_orders = [];
            onUpdateOrdersCallback([]);
            stop_permission = null;
            setHasAppContract(false);
            setUnlocked(true);
        }
    },
    restore: async function()
    {
        if('ultra' in window)
        {
            ultra.connect({ onlyIfTrusted: true }).then((res) => {
                if(res.status === "success")
                {
                    // session[0] -> actor, session[1] -> permission
                    session = res.data.blockchainid.split('@');
                    didLogin();
                }
                else
                {
                    console.log(res.message);
                }
            });
        }
    },

    limitOrder: async function(isBuy, quantity, price)
    {
        if(!session || quantity === "" || price === "") return;
        let m = markets.get(mid);
        let res = await ultra.signTransaction({
            contract: isBuy ? m.market.quote_symbol_contract : m.market.base_symbol_contract,
            action: "transfer",
            data: {
                from: session[0],
                to: contractName,
                quantity: parseFloat(quantity).toFixed(isBuy ? quote_precision(m) : base_precision(m)) + " " + (isBuy ? quote_symbolcode(m) : base_symbolcode(m)),
                memo: "exchange " + mid + " " + parseFloat(price).toFixed(m.market.price_decimals).replace(".", "") + " " + nextOrderIdHex()
            },
        });
        console.log(res);
        if(res.status === "success")
        {
            setTimeout(async () => {
                balances = await fetchJson('/balances', { user: session[0], mid });
                onUpdateBalancesCallback(balances);
            }, 1500);
        }
    },
    stopOrder: async function(isBuy, quantity, stopPrice, limitPrice)
    {
        if(!session || !stop_permission || quantity === "" || stopPrice === "" || limitPrice === "") return;
        let m = markets.get(mid);
        if(isBuy && parseFloat(stopPrice) <= parseFloat(((m.market.amount_quote / Math.pow(10, quote_precision(m))) / (m.market.amount_base / Math.pow(10, base_precision(m)))).toFixed(m.market.price_decimals)))
        {
            alert("Stop-Price must be greater than current market price");
            return;
        }
        if(!isBuy && parseFloat(stopPrice) >= parseFloat(((m.market.amount_quote / Math.pow(10, quote_precision(m))) / (m.market.amount_base / Math.pow(10, base_precision(m)))).toFixed(m.market.price_decimals)))
        {
            alert("Stop-Price must be smaller than current market price");
            return;
        }
        const aes_key = genRandHex(32);
        const order_ct = encryptOrder(
            m.id.toString(),
            parseFloat(quantity).toFixed(isBuy ? quote_precision(m) : base_precision(m)).replace(".", ""),
            isBuy ? m.market.quote_symbol : m.market.base_symbol,
            isBuy ? m.market.quote_symbol_contract : m.market.base_symbol_contract,
            parseFloat(stopPrice).toFixed(m.market.price_decimals).replace(".", ""),
            parseFloat(limitPrice).toFixed(m.market.price_decimals).replace(".", ""),
            Date.now().toString(),
            aes_key
        );
        const aes_key_ct = encryptBytes(aes_key, stop_permission.pw);
        const action = {
            contract: session[0],
            action: "addorder",
            data: {
                id: nextOrderIdHex(),
                aes_key_ct,
                order_ct
            },
        };
        let res = await ultra.signTransaction(action);
        console.log(res);
        if(res.status === "success")
        {
            setTimeout(async () => {
                fetchStopOrders();
            }, 1500);
        }
    },
    deposit: async function(base, quote)
    {
        if(!session) return;
        let m = markets.get(mid);
        let res = await ultra.signTransaction([{
            contract: m.market.base_symbol_contract,
            action: "transfer",
            data: {
                from: session[0],
                to: contractName,
                quantity: parseFloat(base).toFixed(base_precision(m)) + " " + base_symbolcode(m),
                memo: "base"
            },
        }, {
            contract: m.market.quote_symbol_contract,
            action: "transfer",
            data: {
                from: session[0],
                to: contractName,
                quantity: parseFloat(quote).toFixed(quote_precision(m)) + " " + quote_symbolcode(m),
                memo: "liquidity " + m.id
            },
        }]);
        console.log(res);
        if(res.status === "success")
        {
            setTimeout(async () => {
                balances = await fetchJson('/balances', { user: session[0], mid });
                onUpdateBalancesCallback(balances);
                if(ftv_cfg.shares_mid === mid)
                {
                    ftv_ctx = await fetchJson('/ftvctx', { user: session[0], shares_amount: balances[2] });
                    onUpdateFtvCtxCallback(ftv_ctx);
                }
            }, 1500);
        }
    },
    withdraw: async function(shares)
    {
        if(!session) return;
        let m = markets.get(mid);
        let res = await ultra.signTransaction({
            contract: contractName,
            action: "transfer",
            data: {
                from: session[0],
                to: contractName,
                quantity: parseFloat(shares).toFixed(0) + " " + market2SharesSymbolCode(m.id),
                memo: ""
            },
        });
        console.log(res);
        if(res.status === "success")
        {
            setTimeout(async () => {
                balances = await fetchJson('/balances', { user: session[0], mid });
                onUpdateBalancesCallback(balances);
                if(ftv_cfg.shares_mid === mid)
                {
                    ftv_ctx = await fetchJson('/ftvctx', { user: session[0], shares_amount: balances[2] });
                    onUpdateFtvCtxCallback(ftv_ctx);
                }
            }, 1500);
        }
    },
    cancelOrder: async function(market_id, order_id, is_bid, is_stop)
    {
        if(!session) return;
        let action;
        if(is_stop)
        {
            action = {
                contract: session[0],
                action: "rmorder",
                data: {
                    id: order_id,
                }
            }
        }
        else
        {
            action = {
                contract: contractName,
                action: "cancelorder",
                data: {
                    market_id,
                    user: session[0],
                    order_id,
                    is_bid,
                    proof_bytes: ""
                },
            }
        }
        let res = await ultra.signTransaction(action);
        console.log(res);
        if(res.status === "success")
        {
            setTimeout(async () => {
                if(is_stop)
                {
                    fetchStopOrders();
                }
                else
                {
                    fetchMarkets([market_id]);
                    balances = await fetchJson('/balances', { user: session[0], mid });
                    onUpdateBalancesCallback(balances);
                }
            }, 1500);
        }
    },
    enableFtv: async function()
    {
        if(!session) return;
        let res = await ultra.signTransaction({
            contract: contractName,
            action: "enableftv",
            data: {
                user: session[0]
            },
        });
        console.log(res);
        if(res.status === "success")
        {
            setTimeout(async () => {
                ftv_ctx = await fetchJson('/ftvctx', { user: session[0], shares_amount: ftv_ctx.shares_amount });
                onUpdateFtvCtxCallback(ftv_ctx);
            }, 1500);
        }
    },
    deployStopContract: async function(pw)
    {
        if(!session) return;
        let account = (await fetchJson('/account', { account_name: session[0] })).account;
        const actions = [];
        // check if user needs to buy more RAM (stopcontract consumes about 255397 bytes)
        if(account.ram_quota !== -1)
        {
            if((account.ram_quota - account.ram_usage) < 270000)
            {
                actions.push(
                    {
                        contract: "eosio",
                        action: "buyrambytes",
                        data: {
                            bytes: 270000 - (account.ram_quota - account.ram_usage),
                            payer: session[0],
                            receiver: session[0],
                        },
                    }
                );
            }
        }
        // check if contract is already deployed
        let codehash =  (await fetchJson('/codehash', { account_name: session[0] })).codehash;
        if(codehash !== "97f60ee25227d1752b910a7732564eba94259f9c934fb9fe083357cfd3fd6dbe") // old hash: "822af2811ab6c050357fc924dc3ebc3b253a7f845ad80e574282d40b77afce92"
        {
            actions.push(...[
                {
                    contract: "eosio",
                    action: "setcode",
                    data: {
                        account: session[0],
                        vmtype: 0,
                        vmversion: 0,
                        code: await (await fetch(stopContractWasmHex)).text(),
                    },
                },
                {
                    contract: "eosio",
                    action: "setabi",
                    data: {
                        account: session[0],
                        abi: await (await fetch(stopContractAbiHex)).text(),
                    },
                }
            ]);
        }
        // check if active permission already has 'eosio.code' added
        let has_eosio_code = false;
        let has_zeosexecstop = false;
        let req_auth;
        account.permissions.forEach(perm => {
            if(perm.perm_name === 'active')
            {
                perm.required_auth.accounts.forEach(act => {
                    if(act.permission.permission === 'eosio.code')
                    {
                        has_eosio_code = true;
                    }
                });
                req_auth = perm.required_auth;
            }
            if(perm.perm_name === 'zeosexecstop')
            {
                has_zeosexecstop = true;
            }
        });
        if(!has_eosio_code)
        {
            req_auth.accounts.push({
                permission: {
                    actor: session[0],
                    permission: "eosio.code"
                },
                weight: 1
            });
            actions.push({
                contract: "eosio",
                action: "updateauth",
                data: {
                    account: session[0],
                    permission: 'active',
                    parent: 'owner',
                    auth: req_auth
                },
            });
        }
        // generate new key pair for 'zeosexecstop' permission
        const private_key = await ecc.randomKey();
        const public_key = ecc.privateToPublic(private_key);
        // create 'zeosexecstop' permission as child of user's 'active' permission
        actions.push({
            contract: "eosio",
            action: "updateauth",
            data: {
                account: session[0],
                permission: 'zeosexecstop',
                parent: 'active',
                auth: {
                    threshold: 1,
                    accounts: [],
                    keys: [{
                        key: public_key,
                        weight: 1
                    }],
                    waits: []
                }
            },
        });
        // link 'zeosexecstop' permission to 'executeorder' action of stopcontract
        if(!has_zeosexecstop)
        {
            actions.push({
                contract: "eosio",
                action: "linkauth",
                data: {
                    account: session[0],            // the permission's owner to be linked and the payer of the RAM needed to store this link
                    code: session[0],               // the owner of the action to be linked
                    type: 'executeorder',           // the action to be linked
                    requirement: 'zeosexecstop',    // the permission to be linked
                },
            });
        }
        // hash user's password and truncate to 16 bytes for use as aes encryption key
        const pw_bytes = new TextEncoder().encode(pw);
        const pw_digest = buf2hex(await crypto.subtle.digest('SHA-256', pw_bytes)).substring(0, 32);
        const key_ct = encryptBytes(buf2hex(ecc.PrivateKey.fromString(private_key).toBuffer()), pw_digest);

        let res = await ultra.signTransaction(actions);
        console.log(res);
        if(res.status === "success")
        {
            alert("Success! Execute the second transaction in order to save your new 'zeosexecstop' permission's private key in your account.");
            // store 'zeosexecstop' permission's private key encrypted in stopcontracts 'permission' table
            const action = {
                contract: session[0],
                action: "setpermkey",
                data: {
                    key_ct: key_ct
                }
            };
            res = await ultra.signTransaction(action);
            console.log(res);
            if(res.status === "success")
            {
                stop_permission = {
                    pw: pw_digest,
                    key: private_key.toString()
                };
                setCodeHash("97f60ee25227d1752b910a7732564eba94259f9c934fb9fe083357cfd3fd6dbe");
                setUnlocked(true);
                // TODO: notify user about success?
            }
        }
    },
    unlockStopContract: async function(pw)
    {
        if(!session) return;
        // fetch permission
        const key_ct =  (await fetchJson('/permission', { account_name: session[0] })).key_ct;
        // hash user's password and truncate to 16 bytes for use as aes encryption key
        const pw_bytes = new TextEncoder().encode(pw);
        const pw_digest = buf2hex(await crypto.subtle.digest('SHA-256', pw_bytes)).substring(0, 32);
        const private_key = ecc.PrivateKey.fromHex(decryptBytes(key_ct, pw_digest).toString());
        // check integrity of private key
        let public_key = "";
        (await fetchJson('/account', { account_name: session[0] })).account.permissions.forEach(perm => {
            if(perm.perm_name === 'zeosexecstop')
            {
                public_key = perm.required_auth.keys[0].key;
            }
        });
        if(public_key !== ecc.privateToPublic(private_key))
        {
            alert("wrong password");
            setShowUnlockModal(true);
        }
        else
        {
            stop_permission = {
                pw: pw_digest,
                key: private_key.toString()
            };
            setShowUnlockModal(false);
            setUnlocked(true);
            fetchStopOrders();
        }
    },
    resetStopContract: async function(pw)
    {
        if(!session) return;
        const actions = [];
        // generate new key pair for 'zeosexecstop' permission
        const private_key = await ecc.randomKey();
        const public_key = ecc.privateToPublic(private_key);
        // create 'zeosexecstop' permission as child of user's 'active' permission
        actions.push({
            contract: "eosio",
            action: "updateauth",
            data: {
                account: session[0],
                permission: 'zeosexecstop',
                parent: 'active',
                auth: {
                    threshold: 1,
                    accounts: [],
                    keys: [{
                        key: public_key,
                        weight: 1
                    }],
                    waits: []
                }
            }
        });
        // hash user's password and truncate to 16 bytes for use as aes encryption key
        const pw_bytes = new TextEncoder().encode(pw);
        const pw_digest = buf2hex(await crypto.subtle.digest('SHA-256', pw_bytes)).substring(0, 32);
        const key_ct = encryptBytes(buf2hex(ecc.PrivateKey.fromString(private_key).toBuffer()), pw_digest);
        // store 'zeosexecstop' permission's private key encrypted in stopcontracts 'permission' table
        actions.push({
            contract: session[0],
            action: "setpermkey",
            data: {
                key_ct: key_ct
            }
        });
        let res = await ultra.signTransaction(actions);
        console.log(res);
        if(res.status === "success")
        {
            stop_permission = {
                pw: pw_digest,
                key: private_key.toString()
            };
            setShowUnlockModal(false);
            setUnlocked(true);
            // TODO: notify user about success?
        }
    },

    setSymbol: async function(symbolName)
    {
        let i = symbols.findIndex(({ symbol }) => symbol === symbolName);
        if(-1 === i)
        {
            console.log('[setSymbol]: Cannot resolve symbol', symbolName);
            return;
        }
        window.tvWidget.setSymbol(symbols[i].full_name, "1D", ()=>{});
    },

    getSymbols: function()
    {
        return symbols;
    },

    onReady: async (callback) => {
        console.log('[onReady]: Method call');
        setTimeout(() => callback(configurationData));
    },

    searchSymbols: async (
        userInput,
        exchange,
        symbolType,
        onResultReadyCallback
    ) => {
        console.log('[searchSymbols]: Method call');
        onResultReadyCallback(symbols);
    },

    setNewSymbol: async (symbolName) => {
        console.log('[setNewSymbol]: Method call', symbolName);
        mid = symbols.findIndex(({ full_name }) => full_name === symbolName);
        if(-1 === mid)
        {
            console.log('[setNewSymbol]: Cannot resolve symbol', symbolName);
            return;
        }
        if(session)
        {
            balances = await fetchJson('/balances', { user: session[0], mid });
            onUpdateBalancesCallback(balances);
        }
    },

    resolveSymbol: async (
        symbolName,
        onSymbolResolvedCallback,
        onResolveErrorCallback,
        extension
    ) => {
        console.log('[resolveSymbol]: Method call', symbolName);
        let id = symbols.findIndex(({ full_name }) => full_name === symbolName);
        if (-1 === id) {
            console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
            onResolveErrorCallback('Cannot resolve symbol');
            return;
        }
        const symbolInfo = {
            ticker: symbols[id].full_name,
            name: symbols[id].symbol,
            description: symbols[id].description,
            type: symbols[id].type,
            session: '24x7',
            timezone: 'Etc/UTC',
            exchange: symbols[id].exchange,
            minmov: 1,
            pricescale: symbols[id].price_scale,
            visible_plots_set: 'ohlc',
            has_weekly_and_monthly: false,
            has_intraday: true,
            intraday_multipliers: ['1', '60'],
            supported_resolutions: configurationData.supported_resolutions,
            volume_precision: symbols[id].volume_precision,
            data_status: 'streaming',
        };
        console.log('[resolveSymbol]: Symbol resolved', symbolName);
        setTimeout(() => onSymbolResolvedCallback(symbolInfo));
    },

    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        const { from, to, firstDataRequest } = periodParams;
        console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
        let id = symbols.findIndex(({ full_name }) => full_name === symbolInfo.full_name);
        if (-1 === id) {
            console.log('[getBars]: Cannot resolve symbol', symbolInfo.full_name);
            onErrorCallback('Cannot resolve symbol');
            return;
        }
        let m = markets.get(id);
        let bars = await fetchJson('/bars', { id: m.id, from: from * 1000, to: to * 1000, resolution });
        last_bar.set(m.id, bars[bars.length-1]);
        console.log(`[getBars]: returned ${bars.length} bar(s)`);
        onHistoryCallback(bars, { noData: false });
    },

    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
        console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
        let id = symbols.findIndex(({ full_name }) => full_name === symbolInfo.full_name);
        if (-1 === id) {
            console.log('[subscribeBars]: Cannot resolve symbol', symbolInfo.full_name);
            return;
        }
        var newSub = {
            resolution,
            symbolInfo,
            lastBar: last_bar.get(id),
            update: onRealtimeCallback,
            reset: onResetCacheNeededCallback
        };
        tv_subs.set(subscriberUID, newSub);
    },

    unsubscribeBars: (subscriberUID) => {
        console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
        tv_subs.delete(subscriberUID);
    },
};
