import { useEffect, useState, useRef } from 'react';
import styles from './Interface.module.scss';
import Controller from './Controller';
import Popup from 'reactjs-popup';
import redCross from './red-cross.png'

function Tab({ label, onClick, isActive })
{
    return (
        <div className={styles['tab'] + ' ' + (isActive ? styles['active'] : '')} onClick={onClick}>
            {label}
        </div>
    );
}

var limit;
function Limit({ market, balances, ftvBase, ftvQuote })
{
    // BUY side
    const limitPriceBuyRef = useRef();
    const limitAmountBuyRef = useRef();
    const limitSliderBuyRef = useRef();
    const limitTotalBuyRef = useRef();
    const [limitPriceBuy, setLimitPriceBuy] = useState("");
    const [limitAmountBuy, setLimitAmountBuy] = useState("");
    const [limitSliderBuy, setLimitSliderBuy] = useState("0");
    const [limitTotalBuy, setLimitTotalBuy] = useState("");
    function onLimitPriceBuyChange()
    {
        setLimitPriceBuy(limitPriceBuyRef.current.value);
        if(limitTotalBuy !== "")
        {
            setLimitAmountBuy((limitTotalBuy / limitPriceBuyRef.current.value).toFixed(market.basePrecision));
        }
    }
    function onLimitAmountBuyChange()
    {
        setLimitAmountBuy(limitAmountBuyRef.current.value);
        let price = limitPriceBuy;
        if(limitPriceBuy === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision))).toFixed(market.priceDecimals);
            setLimitPriceBuy(price);
        }
        let total = (limitAmountBuyRef.current.value * price).toFixed(market.quotePrecision);
        setLimitTotalBuy(total);
        setLimitSliderBuy(total / balances[1] * 100.0);
    }
    function onLimitSliderBuyChange()
    {
        setLimitSliderBuy(limitSliderBuyRef.current.value);
        let total = (limitSliderBuyRef.current.value / 100.0 * balances[1]).toFixed(market.quotePrecision);
        setLimitTotalBuy(total);
        let price = limitPriceBuy;
        if(limitPriceBuy === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision))).toFixed(market.priceDecimals);
            setLimitPriceBuy(price);
        }
        let amount = (total / price).toFixed(market.basePrecision);
        setLimitAmountBuy(amount);
        if(limitSliderBuyRef.current.value === "0")
        {
            setLimitPriceBuy("");
            setLimitAmountBuy("");
            setLimitTotalBuy("");
        }
    }
    function onLimitTotalBuyChange()
    {
        setLimitTotalBuy(limitTotalBuyRef.current.value);
        let price = limitPriceBuy;
        if(limitPriceBuy === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision))).toFixed(market.priceDecimals);
            setLimitPriceBuy(price);
        }
        let amount = (limitTotalBuyRef.current.value / price).toFixed(market.basePrecision);
        setLimitAmountBuy(amount);
        setLimitSliderBuy(limitTotalBuyRef.current.value / balances[1] * 100.0);
    }

    // SELL side
    const limitPriceSellRef = useRef();
    const limitAmountSellRef = useRef();
    const limitSliderSellRef = useRef();
    const limitTotalSellRef = useRef();
    const [limitPriceSell, setLimitPriceSell] = useState("");
    const [limitAmountSell, setLimitAmountSell] = useState("");
    const [limitSliderSell, setLimitSliderSell] = useState("0");
    const [limitTotalSell, setLimitTotalSell] = useState("");
    function onLimitPriceSellChange()
    {
        setLimitPriceSell(limitPriceSellRef.current.value);
        if(limitAmountSell !== "")
        {
            setLimitTotalSell((limitAmountSell * limitPriceSellRef.current.value).toFixed(market.quotePrecision));
        }
    }
    function onLimitTotalSellChange()
    {
        setLimitTotalSell(limitTotalSellRef.current.value);
        let price = limitPriceSell;
        if(limitPriceSell === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision))).toFixed(market.priceDecimals);
            setLimitPriceSell(price);
        }
        let amount = (limitTotalSellRef.current.value / price).toFixed(market.basePrecision);
        setLimitAmountSell(amount);
        setLimitSliderSell(amount / balances[0] * 100.0);
    }
    function onLimitSliderSellChange()
    {
        setLimitSliderSell(limitSliderSellRef.current.value);
        let amount = (limitSliderSellRef.current.value / 100.0 * balances[0]).toFixed(market.basePrecision);
        setLimitAmountSell(amount);
        let price = limitPriceSell;
        if(limitPriceSell === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision))).toFixed(market.priceDecimals);
            setLimitPriceSell(price);
        }
        let total = (amount * price).toFixed(market.quotePrecision);
        setLimitTotalSell(total);
        if(limitSliderSellRef.current.value === "0")
        {
            setLimitPriceSell("");
            setLimitAmountSell("");
            setLimitTotalSell("");
        }
    }
    function onLimitAmountSellChange()
    {
        setLimitAmountSell(limitAmountSellRef.current.value);
        let price = limitPriceSell;
        if(limitPriceSell === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision))).toFixed(market.priceDecimals);
            setLimitPriceSell(price);
        }
        let total = (limitAmountSellRef.current.value * price).toFixed(market.quotePrecision);
        setLimitTotalSell(total);
        setLimitSliderSell(limitAmountSellRef.current.value / balances[0] * 100.0);
    }

    limit = {
        setPriceBuy: setLimitPriceBuy,
        setAmountBuy: setLimitAmountBuy,
        setSliderBuy: setLimitSliderBuy,
        setTotalBuy: setLimitTotalBuy,
        setPriceSell: setLimitPriceSell,
        setAmountSell: setLimitAmountSell,
        setSliderSell: setLimitSliderSell,
        setTotalSell: setLimitTotalSell,
    }

    return (
        <div className={styles['row']}>
            <div className={styles['col']}>
                <div className={styles['item']}>
                    <div className={styles['th']}>Balance</div>
                    <div className={styles['balance-wrapper']}>
                        <div className={styles['balance']}>{balances[1].toFixed(market.quotePrecision)} {market.quoteSymbolCode}</div>
                        <div className={styles['po-balance']}>${(balances[1] * market.quote_price_usd).toFixed(2)}</div>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={limitPriceBuyRef} value={limitPriceBuy} onChange={onLimitPriceBuyChange} type="number" className="input-field" placeholder="Limit Price" name="limit-price-buy" id='limit-price-buy' required />
                        <label for="limit-price-buy" className="input-label">Limit Price</label>
                        <span className="input-label-ex">
                            <span>Limit Price</span>
                            {limitPriceBuy ? <span className={styles['po-balance']}>${(limitPriceBuy * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={limitAmountBuyRef} value={limitAmountBuy} onChange={onLimitAmountBuyChange} type="number" className="input-field" placeholder="Amount" name="limit-amount-buy" id='limit-amount-buy' required />
                        <label for="limit-amount-buy" className="input-label">Amount</label>
                        <span className="input-label-ex">
                            <span>Amount</span>
                            {limitAmountBuy ? <span className={styles['po-balance']}>${(limitAmountBuy * market.base_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.baseSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <input ref={limitSliderBuyRef} value={limitSliderBuy} onChange={onLimitSliderBuyChange} type="range" min="0" max="100" defaultValue="0" list="range-detents" id='limit-range-buy' />
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={limitTotalBuyRef} value={limitTotalBuy} onChange={onLimitTotalBuyChange} type="number" className="input-field" placeholder="Total" name="limit-total-buy" id='limit-total-buy' required />
                        <label for="limit-total-buy" className="input-label">Total</label>
                        <span className="input-label-ex" style={limitTotalBuy ? {} : {right: '0.5em'}}>
                            <span>Total</span>
                            {ftvQuote !== null ? <span className={ftvQuote === 0 ? "red" : (ftvQuote > 0 ? "green" : "yellow")}>Free Vol.{ftvQuote < 0 ? <>&#8776;</> : <>:</>} {Math.abs(ftvQuote).toFixed(market.quotePrecision)} {market.quoteSymbolCode}</span> : <></>}
                            {limitTotalBuy ? <span className={styles['po-balance']}>${(limitTotalBuy * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <button className='button button-buy' onClick={() => Controller.limitOrder(true, limitTotalBuy, limitPriceBuy)}>BUY {market.baseSymbolCode}</button>
                </div>
            </div>
            <div className={styles['col']}>
                <div className={styles['item']}>
                    <div className={styles['th']}>Balance</div>
                    <div className={styles['balance-wrapper']}>
                        <div className={styles['balance']}>{balances[0].toFixed(market.basePrecision)} {market.baseSymbolCode}</div>
                        <div className={styles['po-balance']}>${(balances[0] * market.base_price_usd).toFixed(2)}</div>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={limitPriceSellRef} value={limitPriceSell} onChange={onLimitPriceSellChange} type="number" className="input-field" placeholder="Limit Price" name="limit-price-sell" id='limit-price-sell' required />
                        <label for="limit-price-sell" className="input-label">Limit Price</label>
                        <span className="input-label-ex">
                            <span>Limit Price</span>
                            {limitPriceSell ? <span className={styles['po-balance']}>${(limitPriceSell * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={limitAmountSellRef} value={limitAmountSell} onChange={onLimitAmountSellChange} type="number" className="input-field" placeholder="Amount" name="limit-amount-sell" id='limit-amount-sell' required />
                        <label for="limit-amount-sell" className="input-label">Amount</label>
                        <span className="input-label-ex" style={limitAmountSell ? {} : {right: '0.5em'}}>
                            <span>Amount</span>
                            {ftvBase !== null ? <span className={ftvBase === 0 ? "red" : (ftvBase > 0 ? "green" : "yellow")}>Free Vol.{ftvBase < 0 ? <>&#8776;</> : <>:</>} {Math.abs(ftvBase).toFixed(market.basePrecision)} {market.baseSymbolCode}</span> : <></>}
                            {limitAmountSell ? <span className={styles['po-balance']}>${(limitAmountSell * market.base_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.baseSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <input ref={limitSliderSellRef} value={limitSliderSell} onChange={onLimitSliderSellChange} type="range" min="0" max="100" defaultValue="0" list="range-detents" id='limit-range-sell' />
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={limitTotalSellRef} value={limitTotalSell} onChange={onLimitTotalSellChange} type="number" className="input-field" placeholder="Total" name="limit-total-sell" id='limit-total-sell' required />
                        <label for="limit-total-sell" className="input-label">Total</label>
                        <span className="input-label-ex">
                            <span>Total</span>
                            {limitTotalSell ? <span className={styles['po-balance']}>${(limitTotalSell * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <button className='button button-sell' onClick={() => Controller.limitOrder(false, limitAmountSell, limitPriceSell)}>SELL {market.baseSymbolCode}</button>
                </div>
            </div>
        </div>
    );
}

function Market({ market, balances, ftvBase, ftvQuote })
{
    // BUY side
    const marketSliderBuyRef = useRef();
    const marketTotalBuyRef = useRef();
    const [marketPriceBuy, setMarketPriceBuy] = useState("");
    const [marketAmountBuy, setMarketAmountBuy] = useState("");
    const [marketSliderBuy, setMarketSliderBuy] = useState("0");
    const [marketTotalBuy, setMarketTotalBuy] = useState("");
    function onMarketSliderBuyChange()
    {
        setMarketSliderBuy(marketSliderBuyRef.current.value);
        let total = (marketSliderBuyRef.current.value / 100.0 * balances[1]).toFixed(market.quotePrecision);
        setMarketTotalBuy(total);
        let order = "{\"amount_open\":" + parseInt(total * Math.pow(10, market.quotePrecision)) + ",\"amount_dealt\":0,\"price\":2147483647,\"owner\":\"owner\",\"id\":\"id\",\"block_num\":0}";
        let amount = Controller.marketBuyPreview(order, ftvQuote === null ? 0 : Math.abs(ftvQuote));
        setMarketAmountBuy(amount);
        if(amount > 0)
        {
            setMarketPriceBuy((total / amount).toFixed(market.priceDecimals));
        }
        if(marketSliderBuyRef.current.value === "0")
        {
            setMarketPriceBuy("");
            setMarketAmountBuy("");
            setMarketTotalBuy("");
        }
    }
    function onMarketTotalBuyChange()
    {
        setMarketTotalBuy(marketTotalBuyRef.current.value);
        setMarketSliderBuy(marketTotalBuyRef.current.value / balances[1] * 100.0);
        let order = "{\"amount_open\":" + parseInt(marketTotalBuyRef.current.value * Math.pow(10, market.quotePrecision)) + ",\"amount_dealt\":0,\"price\":2147483647,\"owner\":\"owner\",\"id\":\"id\",\"block_num\":0}";
        let amount = Controller.marketBuyPreview(order, ftvQuote === null ? 0 : Math.abs(ftvQuote));
        setMarketAmountBuy(amount);
        if(amount > 0)
        {
            setMarketPriceBuy((marketTotalBuyRef.current.value / amount).toFixed(market.priceDecimals));
        }
    }

    // SELL side
    const marketAmountSellRef = useRef();
    const marketSliderSellRef = useRef();
    const [marketPriceSell, setMarketPriceSell] = useState("");
    const [marketAmountSell, setMarketAmountSell] = useState("");
    const [marketSliderSell, setMarketSliderSell] = useState("0");
    const [marketTotalSell, setMarketTotalSell] = useState("");
    function onMarketSliderSellChange()
    {
        setMarketSliderSell(marketSliderSellRef.current.value);
        let amount = (marketSliderSellRef.current.value / 100.0 * balances[0]).toFixed(market.basePrecision);
        setMarketAmountSell(amount);
        let order = "{\"amount_open\":" + parseInt(amount * Math.pow(10, market.basePrecision)) + ",\"amount_dealt\":0,\"price\":0,\"owner\":\"owner\",\"id\":\"id\",\"block_num\":0}";
        let total = Controller.marketSellPreview(order, ftvBase === null ? 0 : Math.abs(ftvBase));
        setMarketTotalSell(total);
        if(amount > 0)
        {
            setMarketPriceSell((total / amount).toFixed(market.priceDecimals));
        }
        if(marketSliderSellRef.current.value === "0")
        {
            setMarketPriceSell("");
            setMarketAmountSell("");
            setMarketTotalSell("");
        }
    }
    function onMarketAmountSellChange()
    {
        setMarketAmountSell(marketAmountSellRef.current.value);
        setMarketSliderSell(marketAmountSellRef.current.value / balances[0] * 100.0);
        let order = "{\"amount_open\":" + parseInt(marketAmountSellRef.current.value * Math.pow(10, market.basePrecision)) + ",\"amount_dealt\":0,\"price\":0,\"owner\":\"owner\",\"id\":\"id\",\"block_num\":0}";
        let total = Controller.marketSellPreview(order, ftvBase === null ? 0 : Math.abs(ftvBase));
        setMarketTotalSell(total);
        if(marketAmountSellRef.current.value > 0)
        {
            setMarketPriceSell((total / marketAmountSellRef.current.value).toFixed(market.priceDecimals));
        }
    }

    return (
        <div className={styles['row']}>
            <div className={styles['col']}>
                <div className={styles['item']}>
                    <div className={styles['th']}>Balance</div>
                    <div className={styles['balance-wrapper']}>
                        <div className={styles['balance']}>{balances[1].toFixed(market.quotePrecision)} {market.quoteSymbolCode}</div>
                        <div className={styles['po-balance']}>${(balances[1] * market.quote_price_usd).toFixed(2)}</div>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input value={marketPriceBuy} type="number" className="input-field" placeholder="Average Price (incl. fee)" name="market-price-buy" id='market-price-buy' disabled='disabled' required />
                        <label for="market-price-buy" className="input-label">Average Price (incl. fee)</label>
                        <span className="input-label-ex">
                            <span>Average Price (incl. fee)</span>
                            {marketPriceBuy ? <span className={styles['po-balance']}>${(marketPriceBuy * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input value={marketAmountBuy} type="number" className="input-field" placeholder="Estimated Amount" name="market-amount-buy" id='market-amount-buy' disabled='disabled' required />
                        <label for="market-amount-buy" className="input-label">Estimated Amount</label>
                        <span className="input-label-ex">
                            <span>Estimated Amount</span>
                            {marketAmountBuy ? <span className={styles['po-balance']}>${(marketAmountBuy * market.base_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.baseSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <input ref={marketSliderBuyRef} value={marketSliderBuy} onChange={onMarketSliderBuyChange} type="range" min="0" max="100" defaultValue="0" list="range-detents" id='market-range-buy' />
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={marketTotalBuyRef} value={marketTotalBuy} onChange={onMarketTotalBuyChange} type="number" className="input-field" placeholder="Total" name="market-total-buy" id='market-total-buy' required />
                        <label for="market-total-buy" className="input-label">Total</label>
                        <span className="input-label-ex" style={marketTotalBuy ? {} : {right: '0.5em'}}>
                            <span>Total</span>
                            {ftvQuote !== null ? <span className={ftvQuote === 0 ? "red" : (ftvQuote > 0 ? "green" : "yellow")}>Free Vol.{ftvQuote < 0 ? <>&#8776;</> : <>:</>} {Math.abs(ftvQuote).toFixed(market.quotePrecision)} {market.quoteSymbolCode}</span> : <></>}
                            {marketTotalBuy ? <span className={styles['po-balance']}>${(marketTotalBuy * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <button className='button button-buy' onClick={() => Controller.limitOrder(true, marketTotalBuy, "2147483647")}>BUY {market.baseSymbolCode}</button>
                </div>
            </div>
            <div className={styles['col']}>
                <div className={styles['item']}>
                    <div className={styles['th']}>Balance</div>
                    <div className={styles['balance-wrapper']}>
                        <div className={styles['balance']}>{balances[0].toFixed(market.basePrecision)} {market.baseSymbolCode}</div>
                        <div className={styles['po-balance']}>${(balances[0] * market.base_price_usd).toFixed(2)}</div>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input value={marketPriceSell} type="number" className="input-field" placeholder="Average Price (incl. fee)" name="market-price-sell" id='market-price-sell' disabled='disabled' required />
                        <label for="market-price-sell" className="input-label">Average Price (incl. fee)</label>
                        <span className="input-label-ex">
                            <span>Average Price (incl. fee)</span>
                            {marketPriceSell ? <span className={styles['po-balance']}>${(marketPriceSell * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={marketAmountSellRef} value={marketAmountSell} onChange={onMarketAmountSellChange} type="number" className="input-field" placeholder="Amount" name="market-amount-sell" id='market-amount-sell' required />
                        <label for="market-amount-sell" className="input-label">Amount</label>
                        <span className="input-label-ex" style={marketAmountSell ? {} : {right: '0.5em'}}>
                            <span>Amount</span>
                            {ftvBase !== null ? <span className={ftvBase === 0 ? "red" : (ftvBase > 0 ? "green" : "yellow")}>Free Vol.{ftvBase < 0 ? <>&#8776;</> : <>:</>} {Math.abs(ftvBase).toFixed(market.basePrecision)} {market.baseSymbolCode}</span> : <></>}
                            {marketAmountSell ? <span className={styles['po-balance']}>${(marketAmountSell * market.base_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.baseSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <input ref={marketSliderSellRef} value={marketSliderSell} onChange={onMarketSliderSellChange} type="range" min="0" max="100" defaultValue="0" list="range-detents" id='market-range-sell' />
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input value={marketTotalSell} type="number" className="input-field" placeholder="Estimated Total" name="market-total-sell" id='market-total-sell' disabled='disabled' required />
                        <label for="market-total-sell" className="input-label">Estimated Total</label>
                        <span className="input-label-ex">
                            <span>Estimated Total</span>
                            {marketTotalSell ? <span className={styles['po-balance']}>${(marketTotalSell * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <button className='button button-sell' onClick={() => Controller.limitOrder(false, marketAmountSell, "0")}>SELL {market.baseSymbolCode}</button>
                </div>
            </div>
        </div>
    );
}

function Stop({ market, balances, ftvBase, ftvQuote })
{
    // BUY side
    const stopPriceBuyRef = useRef();
    const stopSliderBuyRef = useRef();
    const stopTotalBuyRef = useRef();
    const [stopPriceBuy, setStopPriceBuy] = useState("");
    const [stopSliderBuy, setStopSliderBuy] = useState("0");
    const [stopTotalBuy, setStopTotalBuy] = useState("");
    function onStopPriceBuyChange()
    {
        setStopPriceBuy(stopPriceBuyRef.current.value);
    }
    function onStopSliderBuyChange()
    {
        setStopSliderBuy(stopSliderBuyRef.current.value);
        let total = (stopSliderBuyRef.current.value / 100.0 * balances[1]).toFixed(market.quotePrecision);
        setStopTotalBuy(total);
        let price = stopPriceBuy;
        if(stopPriceBuy === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision)) + 1.0 / Math.pow(10, market.priceDecimals)).toFixed(market.priceDecimals);
            setStopPriceBuy(price);
        }
        if(stopSliderBuyRef.current.value === "0")
        {
            setStopPriceBuy("");
            setStopTotalBuy("");
        }
    }
    function onStopTotalBuyChange()
    {
        setStopTotalBuy(stopTotalBuyRef.current.value);
        setStopSliderBuy(stopTotalBuyRef.current.value / balances[1] * 100.0);
        let price = stopPriceBuy;
        if(stopPriceBuy === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision)) + 1.0 / Math.pow(10, market.priceDecimals)).toFixed(market.priceDecimals);
            setStopPriceBuy(price);
        }
    }

    // SELL side
    const stopPriceSellRef = useRef();
    const stopAmountSellRef = useRef();
    const stopSliderSellRef = useRef();
    const [stopPriceSell, setStopPriceSell] = useState("");
    const [stopAmountSell, setStopAmountSell] = useState("");
    const [stopSliderSell, setStopSliderSell] = useState("0");
    function onStopPriceSellChange()
    {
        setStopPriceSell(stopPriceSellRef.current.value);
    }
    function onStopSliderSellChange()
    {
        setStopSliderSell(stopSliderSellRef.current.value);
        let amount = (stopSliderSellRef.current.value / 100.0 * balances[0]).toFixed(market.basePrecision);
        setStopAmountSell(amount);
        let price = stopPriceSell;
        if(stopPriceSell === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision)) - 1.0 / Math.pow(10, market.priceDecimals)).toFixed(market.priceDecimals);
            setStopPriceSell(price);
        }
        if(stopSliderSellRef.current.value === "0")
        {
            setStopPriceSell("");
            setStopAmountSell("");
        }
    }
    function onStopAmountSellChange()
    {
        setStopAmountSell(stopAmountSellRef.current.value);
        setStopSliderSell(stopAmountSellRef.current.value / balances[0] * 100.0);
        let price = stopPriceSell;
        if(stopPriceSell === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision)) - 1.0 / Math.pow(10, market.priceDecimals)).toFixed(market.priceDecimals);
            setStopPriceSell(price);
        }
    }

    return (
        <div className={styles['row']}>
            <div className={styles['col']}>
                <div className={styles['item']}>
                    <div className={styles['th']}>Balance</div>
                    <div className={styles['balance-wrapper']}>
                        <div className={styles['balance']}>{balances[1].toFixed(market.quotePrecision)} {market.quoteSymbolCode}</div>
                        <div className={styles['po-balance']}>${(balances[1] * market.quote_price_usd).toFixed(2)}</div>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={stopPriceBuyRef} value={stopPriceBuy} onChange={onStopPriceBuyChange}  type="number" className="input-field" placeholder="Stop Price" name="stop-price-buy" id='stop-price-buy' required />
                        <label for="stop-price-buy" className="input-label">Stop Price</label>
                        <span className="input-label-ex">
                            <span>Stop Price</span>
                            {stopPriceBuy ? <span className={styles['po-balance']}>${(stopPriceBuy * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <input ref={stopSliderBuyRef} value={stopSliderBuy} onChange={onStopSliderBuyChange} type="range" min="0" max="100" defaultValue="0" list="range-detents" id='stop-range-buy' />
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={stopTotalBuyRef} value={stopTotalBuy} onChange={onStopTotalBuyChange} type="number" className="input-field" placeholder="Total" name="stop-total-buy" id='stop-total-buy' required />
                        <label for="stop-total-buy" className="input-label">Total</label>
                        <span className="input-label-ex" style={stopTotalBuy ? {} : {right: '0.5em'}}>
                            <span>Total</span>
                            {ftvQuote !== null ? <span className={ftvQuote === 0 ? "red" : (ftvQuote > 0 ? "green" : "yellow")}>Free Vol.{ftvQuote < 0 ? <>&#8776;</> : <>:</>} {Math.abs(ftvQuote).toFixed(market.quotePrecision)} {market.quoteSymbolCode}</span> : <></>}
                            {stopTotalBuy ? <span className={styles['po-balance']}>${(stopTotalBuy * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <button className='button button-buy' onClick={() => Controller.stopOrder(true, stopTotalBuy, stopPriceBuy, "2147483647")}>BUY {market.baseSymbolCode}</button>
                </div>
            </div>
            <div className={styles['col']}>
                <div className={styles['item']}>
                    <div className={styles['th']}>Balance</div>
                    <div className={styles['balance-wrapper']}>
                        <div className={styles['balance']}>{balances[0].toFixed(market.basePrecision)} {market.baseSymbolCode}</div>
                        <div className={styles['po-balance']}>${(balances[0] * market.base_price_usd).toFixed(2)}</div>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={stopPriceSellRef} value={stopPriceSell} onChange={onStopPriceSellChange}  type="number" className="input-field" placeholder="Stop Price" name="stop-price-sell" id='stop-price-sell' required />
                        <label for="stop-price-sell" className="input-label">Stop Price</label>
                        <span className="input-label-ex">
                            <span>Stop Price</span>
                            {stopPriceSell ? <span className={styles['po-balance']}>${(stopPriceSell * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={stopAmountSellRef} value={stopAmountSell} onChange={onStopAmountSellChange} type="number" className="input-field" placeholder="Amount" name="stop-amount-sell" id='stop-amount-sell' required />
                        <label for="stop-amount-sell" className="input-label">Amount</label>
                        <span className="input-label-ex" style={stopAmountSell ? {} : {right: '0.5em'}}>
                            <span>Amount</span>
                            {ftvBase !== null ? <span className={ftvBase === 0 ? "red" : (ftvBase > 0 ? "green" : "yellow")}>Free Vol.{ftvBase < 0 ? <>&#8776;</> : <>:</>} {Math.abs(ftvBase).toFixed(market.basePrecision)} {market.baseSymbolCode}</span> : <></>}
                            {stopAmountSell ? <span className={styles['po-balance']}>${(stopAmountSell * market.base_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.baseSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <input ref={stopSliderSellRef} value={stopSliderSell} onChange={onStopSliderSellChange} type="range" min="0" max="100" defaultValue="0" list="range-detents" id='stop-range-sell' />
                </div>
                <div className={styles['item']}>
                    <button className='button button-sell' onClick={() => Controller.stopOrder(false, stopAmountSell, stopPriceSell, "0")}>SELL {market.baseSymbolCode}</button>
                </div>
            </div>
        </div>
    );
}

function StopLimit({ market, balances, ftvBase, ftvQuote })
{
    // BUY side
    const stopPriceBuyRef = useRef();
    const limitPriceBuyRef = useRef();
    const limitAmountBuyRef = useRef();
    const limitSliderBuyRef = useRef();
    const limitTotalBuyRef = useRef();
    const [stopPriceBuy, setStopPriceBuy] = useState("");
    const [limitPriceBuy, setLimitPriceBuy] = useState("");
    const [limitAmountBuy, setLimitAmountBuy] = useState("");
    const [limitSliderBuy, setLimitSliderBuy] = useState("0");
    const [limitTotalBuy, setLimitTotalBuy] = useState("");
    function onStopPriceBuyChange()
    {
        setStopPriceBuy(stopPriceBuyRef.current.value);
    }
    function onLimitPriceBuyChange()
    {
        setLimitPriceBuy(limitPriceBuyRef.current.value);
        if(limitTotalBuy !== "")
        {
            setLimitAmountBuy((limitTotalBuy / limitPriceBuyRef.current.value).toFixed(market.basePrecision));
        }
    }
    function onLimitAmountBuyChange()
    {
        setLimitAmountBuy(limitAmountBuyRef.current.value);
        if(stopPriceBuy === "")
        {
            let sprice = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision)) + 1.0 / Math.pow(10, market.priceDecimals)).toFixed(market.priceDecimals);
            setStopPriceBuy(sprice);
        }
        let price = limitPriceBuy;
        if(limitPriceBuy === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision))).toFixed(market.priceDecimals);
            setLimitPriceBuy(price);
        }
        let total = (limitAmountBuyRef.current.value * price).toFixed(market.quotePrecision);
        setLimitTotalBuy(total);
        setLimitSliderBuy(total / balances[1] * 100.0);
    }
    function onLimitSliderBuyChange()
    {
        setLimitSliderBuy(limitSliderBuyRef.current.value);
        let total = (limitSliderBuyRef.current.value / 100.0 * balances[1]).toFixed(market.quotePrecision);
        setLimitTotalBuy(total);
        if(stopPriceBuy === "")
        {
            let sprice = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision)) + 1.0 / Math.pow(10, market.priceDecimals)).toFixed(market.priceDecimals);
            setStopPriceBuy(sprice);
        }
        let price = limitPriceBuy;
        if(limitPriceBuy === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision))).toFixed(market.priceDecimals);
            setLimitPriceBuy(price);
        }
        let amount = (total / price).toFixed(market.basePrecision);
        setLimitAmountBuy(amount);
        if(limitSliderBuyRef.current.value === "0")
        {
            setStopPriceBuy("");
            setLimitPriceBuy("");
            setLimitAmountBuy("");
            setLimitTotalBuy("");
        }
    }
    function onLimitTotalBuyChange()
    {
        setLimitTotalBuy(limitTotalBuyRef.current.value);
        if(stopPriceBuy === "")
        {
            let sprice = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision)) + 1.0 / Math.pow(10, market.priceDecimals)).toFixed(market.priceDecimals);
            setStopPriceBuy(sprice);
        }
        let price = limitPriceBuy;
        if(limitPriceBuy === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision))).toFixed(market.priceDecimals);
            setLimitPriceBuy(price);
        }
        let amount = (limitTotalBuyRef.current.value / price).toFixed(market.basePrecision);
        setLimitAmountBuy(amount);
        setLimitSliderBuy(limitTotalBuyRef.current.value / balances[1] * 100.0);
    }

    // SELL side
    const stopPriceSellRef = useRef();
    const limitPriceSellRef = useRef();
    const limitAmountSellRef = useRef();
    const limitSliderSellRef = useRef();
    const limitTotalSellRef = useRef();
    const [stopPriceSell, setStopPriceSell] = useState("");
    const [limitPriceSell, setLimitPriceSell] = useState("");
    const [limitAmountSell, setLimitAmountSell] = useState("");
    const [limitSliderSell, setLimitSliderSell] = useState("0");
    const [limitTotalSell, setLimitTotalSell] = useState("");
    function onStopPriceSellChange()
    {
        setStopPriceSell(stopPriceSellRef.current.value);
    }
    function onLimitPriceSellChange()
    {
        setLimitPriceSell(limitPriceSellRef.current.value);
        if(limitAmountSell !== "")
        {
            setLimitTotalSell((limitAmountSell * limitPriceSellRef.current.value).toFixed(market.quotePrecision));
        }
    }
    function onLimitTotalSellChange()
    {
        setLimitTotalSell(limitTotalSellRef.current.value);
        if(stopPriceSell === "")
        {
            let sprice = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision)) - 1.0 / Math.pow(10, market.priceDecimals)).toFixed(market.priceDecimals);
            setStopPriceSell(sprice);
        }
        let price = limitPriceSell;
        if(limitPriceSell === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision))).toFixed(market.priceDecimals);
            setLimitPriceSell(price);
        }
        let amount = (limitTotalSellRef.current.value / price).toFixed(market.basePrecision);
        setLimitAmountSell(amount);
        setLimitSliderSell(limitAmountSellRef.current.value / balances[0] * 100.0);
    }
    function onLimitSliderSellChange()
    {
        setLimitSliderSell(limitSliderSellRef.current.value);
        let amount = (limitSliderSellRef.current.value / 100.0 * balances[0]).toFixed(market.basePrecision);
        setLimitAmountSell(amount);
        if(stopPriceSell === "")
        {
            let sprice = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision)) - 1.0 / Math.pow(10, market.priceDecimals)).toFixed(market.priceDecimals);
            setStopPriceSell(sprice);
        }
        let price = limitPriceSell;
        if(limitPriceSell === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision))).toFixed(market.priceDecimals);
            setLimitPriceSell(price);
        }
        let total = (amount * price).toFixed(market.quotePrecision);
        setLimitTotalSell(total);
        if(limitSliderSellRef.current.value === "0")
        {
            setStopPriceSell("");
            setLimitPriceSell("");
            setLimitAmountSell("");
            setLimitTotalSell("");
        }
    }
    function onLimitAmountSellChange()
    {
        setLimitAmountSell(limitAmountSellRef.current.value);
        if(stopPriceSell === "")
        {
            let sprice = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision)) - 1.0 / Math.pow(10, market.priceDecimals)).toFixed(market.priceDecimals);
            setStopPriceSell(sprice);
        }
        let price = limitPriceSell;
        if(limitPriceSell === "")
        {
            price = ((market.quoteAmount / Math.pow(10, market.quotePrecision)) / (market.baseAmount / Math.pow(10, market.basePrecision))).toFixed(market.priceDecimals);
            setLimitPriceSell(price);
        }
        let total = (limitAmountSellRef.current.value * price).toFixed(market.quotePrecision);
        setLimitTotalSell(total);
        setLimitSliderSell(limitAmountSellRef.current.value / balances[0] * 100.0);
    }

    return (
        <div className={styles['row']}>
            <div className={styles['col']}>
                <div className={styles['item']}>
                    <div className={styles['th']}>Balance</div>
                    <div className={styles['balance-wrapper']}>
                        <div className={styles['balance']}>{balances[1].toFixed(market.quotePrecision)} {market.quoteSymbolCode}</div>
                        <div className={styles['po-balance']}>${(balances[1] * market.quote_price_usd).toFixed(2)}</div>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={stopPriceBuyRef} value={stopPriceBuy} onChange={onStopPriceBuyChange} type="number" className="input-field" placeholder="Stop Price" name="stoplimit-stop-price-buy" id='stoplimit-stop-price-buy' required />
                        <label for="stoplimit-stop-price-buy" className="input-label">Stop Price</label>
                        <span className="input-label-ex">
                            <span>Stop Price</span>
                            {stopPriceBuy ? <span className={styles['po-balance']}>${(stopPriceBuy * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={limitPriceBuyRef} value={limitPriceBuy} onChange={onLimitPriceBuyChange} type="number" className="input-field" placeholder="Limit Price" name="stoplimit-limit-price-buy" id='stoplimit-limit-price-buy' required />
                        <label for="stoplimit-limit-price-buy" className="input-label">Limit Price</label>
                        <span className="input-label-ex">
                            <span>Limit Price</span>
                            {limitPriceBuy ? <span className={styles['po-balance']}>${(limitPriceBuy * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={limitAmountBuyRef} value={limitAmountBuy} onChange={onLimitAmountBuyChange} type="number" className="input-field" placeholder="Amount" name="stoplimit-amount-buy" id='stoplimit-amount-buy' required />
                        <label for="stoplimit-amount-buy" className="input-label">Amount</label>
                        <span className="input-label-ex">
                            <span>Amount</span>
                            {limitAmountBuy ? <span className={styles['po-balance']}>${(limitAmountBuy * market.base_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.baseSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <input ref={limitSliderBuyRef} value={limitSliderBuy} onChange={onLimitSliderBuyChange} type="range" min="0" max="100" defaultValue="0" list="range-detents" id='stoplimit-range-buy' />
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={limitTotalBuyRef} value={limitTotalBuy} onChange={onLimitTotalBuyChange} type="number" className="input-field" placeholder="Total" name="stoplimit-total-buy" id='stoplimit-total-buy' required />
                        <label for="stoplimit-total-buy" className="input-label">Total</label>
                        <span className="input-label-ex" style={limitTotalBuy ? {} : {right: '0.5em'}}>
                            <span>Total</span>
                            {ftvQuote !== null ? <span className={ftvQuote === 0 ? "red" : (ftvQuote > 0 ? "green" : "yellow")}>Free Vol.{ftvQuote < 0 ? <>&#8776;</> : <>:</>} {Math.abs(ftvQuote).toFixed(market.quotePrecision)} {market.quoteSymbolCode}</span> : <></>}
                            {limitTotalBuy ? <span className={styles['po-balance']}>${(limitTotalBuy * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <button className='button button-buy' onClick={() => Controller.stopOrder(true, limitTotalBuy, stopPriceBuy, limitPriceBuy)}>BUY {market.baseSymbolCode}</button>
                </div>
            </div>
            <div className={styles['col']}>
                <div className={styles['item']}>
                    <div className={styles['th']}>Balance</div>
                    <div className={styles['balance-wrapper']}>
                        <div className={styles['balance']}>{balances[0].toFixed(market.basePrecision)} {market.baseSymbolCode}</div>
                        <div className={styles['po-balance']}>${(balances[0] * market.base_price_usd).toFixed(2)}</div>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={stopPriceSellRef} value={stopPriceSell} onChange={onStopPriceSellChange} type="number" className="input-field" placeholder="Stop Price" name="stoplimit-stop-price-sell" id='stoplimit-stop-price-sell' required />
                        <label for="stoplimit-stop-price-sell" className="input-label">Stop Price</label>
                        <span className="input-label-ex">
                            <span>Stop Price</span>
                            {stopPriceSell ? <span className={styles['po-balance']}>${(stopPriceSell * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={limitPriceSellRef} value={limitPriceSell} onChange={onLimitPriceSellChange} type="number" className="input-field" placeholder="Limit Price" name="stoplimit-limit-price-sell" id='stoplimit-limit-price-sell' required />
                        <label for="stoplimit-limit-price-sell" className="input-label">Limit Price</label>
                        <span className="input-label-ex">
                            <span>Limit Price</span>
                            {limitPriceSell ? <span className={styles['po-balance']}>${(limitPriceSell * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={limitAmountSellRef} value={limitAmountSell} onChange={onLimitAmountSellChange} type="number" className="input-field" placeholder="Amount" name="stoplimit-amount-sell" id='stoplimit-amount-sell' required />
                        <label for="stoplimit-amount-sell" className="input-label">Amount</label>
                        <span className="input-label-ex" style={limitAmountSell ? {} : {right: '0.5em'}}>
                            <span>Amount</span>
                            {ftvBase !== null ? <span className={ftvBase === 0 ? "red" : (ftvBase > 0 ? "green" : "yellow")}>Free Vol.{ftvBase < 0 ? <>&#8776;</> : <>:</>} {Math.abs(ftvBase).toFixed(market.basePrecision)} {market.baseSymbolCode}</span> : <></>}
                            {limitAmountSell ? <span className={styles['po-balance']}>${(limitAmountSell * market.base_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.baseSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <input ref={limitSliderSellRef} value={limitSliderSell} onChange={onLimitSliderSellChange} type="range" min="0" max="100" defaultValue="0" list="range-detents" id='stoplimit-range-sell' />
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={limitTotalSellRef} value={limitTotalSell} onChange={onLimitTotalSellChange} type="number" className="input-field" placeholder="Total" name="stoplimit-total-sell" id='stoplimit-total-sell' required />
                        <label for="stoplimit-total-sell" className="input-label">Total</label>
                        <span className="input-label-ex">
                            <span>Total</span>
                            {limitTotalSell ? <span className={styles['po-balance']}>${(limitTotalSell * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <button className='button button-sell' onClick={() => Controller.stopOrder(false, limitAmountSell, stopPriceSell, limitPriceSell)}>SELL {market.baseSymbolCode}</button>
                </div>
            </div>
        </div>
    );
}

var depositAmountBaseRefRef;
function LiquidityPool({ market, balances })
{
    // DEPOSIT side
    const depositAmountBaseRef = useRef();
    depositAmountBaseRefRef = depositAmountBaseRef;
    const depositAmountQuoteRef = useRef();
    const depositSliderRef = useRef();
    const [depositAmountBase, setDepositAmountBase] = useState("");
    const [depositAmountQuote, setDepositAmountQuote] = useState("");
    const [depositAmountShares, setDepositAmountShares] = useState("");
    const [depositSlider, setDepositSlider] = useState("0");
    function onDepositAmountBaseChange()
    {
        setDepositAmountBase(depositAmountBaseRef.current.value);
        let aq = market.quoteAmount / Math.pow(10, market.quotePrecision);
        let ab = market.baseAmount / Math.pow(10, market.basePrecision);
        let aqps = aq / market.sharesAmount;
        let abps = ab / market.sharesAmount;
        let aqib = balances[1] / (aq / ab);
        let greater = balances[0] >= aqib ? "base" : "quote";
        let amountQuote = (depositAmountBaseRef.current.value / abps * aqps).toFixed(market.quotePrecision);
        setDepositAmountQuote(amountQuote);
        setDepositAmountShares((depositAmountBaseRef.current.value / abps).toFixed(0));
        if(greater === "quote") setDepositSlider(depositAmountBaseRef.current.value / balances[0] * 100.0);
        else setDepositSlider(amountQuote / balances[1] * 100.0);
    }
    function onDepositAmountQuoteChange()
    {
        setDepositAmountQuote(depositAmountQuoteRef.current.value);
        let aq = market.quoteAmount / Math.pow(10, market.quotePrecision);
        let ab = market.baseAmount / Math.pow(10, market.basePrecision);
        let aqps = aq / market.sharesAmount;
        let abps = ab / market.sharesAmount;
        let aqib = balances[1] / (aq / ab);
        let greater = balances[0] >= aqib ? "base" : "quote";
        let amountBase = (depositAmountQuoteRef.current.value / aqps * abps).toFixed(market.basePrecision);
        setDepositAmountBase(amountBase);
        setDepositAmountShares((depositAmountQuoteRef.current.value / aqps).toFixed(0));
        if(greater === "quote") setDepositSlider(amountBase / balances[0] * 100.0);
        else setDepositSlider(depositAmountQuoteRef.current.value / balances[1] * 100.0);
    }
    function onDepositSliderChange()
    {
        setDepositSlider(depositSliderRef.current.value);
        let aq = market.quoteAmount / Math.pow(10, market.quotePrecision);
        let ab = market.baseAmount / Math.pow(10, market.basePrecision);
        let aqps = aq / market.sharesAmount;
        let abps = ab / market.sharesAmount;
        let aqib = balances[1] / (aq / ab);
        let greater = balances[0] >= aqib ? "base" : "quote";
        if(greater === "quote")
        {
            let amountBase = (depositSliderRef.current.value / 100.0 * balances[0]).toFixed(market.basePrecision);
            setDepositAmountBase(amountBase);
            let amountShares = (amountBase / abps).toFixed(0);
            setDepositAmountShares(amountShares);
            setDepositAmountQuote((amountBase / abps * aqps).toFixed(market.quotePrecision));
        }
        else
        {
            let amountQuote = (depositSliderRef.current.value / 100.0 * balances[1]).toFixed(market.quotePrecision);
            let amountBase = (amountQuote / aqps * abps).toFixed(market.basePrecision);
            amountQuote = (amountBase / abps * aqps).toFixed(market.quotePrecision);
            let amountShares = (amountBase / abps).toFixed(0);
            setDepositAmountBase(amountBase);
            setDepositAmountQuote(amountQuote);
            setDepositAmountShares(amountShares);
        }
        if(depositSliderRef.current.value === "0")
        {
            setDepositAmountBase("");
            setDepositAmountQuote("");
            setDepositAmountShares("");
        }
    }

    // WITHDRAW side
    const withdrawAmountSharesRef = useRef();
    const withdrawSliderRef = useRef();
    const [withdrawAmountBase, setWithdrawAmountBase] = useState("");
    const [withdrawAmountQuote, setWithdrawAmountQuote] = useState("");
    const [withdrawAmountShares, setWithdrawAmountShares] = useState("");
    const [withdrawSlider, setWithdrawSlider] = useState("0");
    function onWithdrawAmountSharesChange()
    {
        setWithdrawAmountShares(withdrawAmountSharesRef.current.value);
        let aq = market.quoteAmount / Math.pow(10, market.quotePrecision);
        let ab = market.baseAmount / Math.pow(10, market.basePrecision);
        let aqps = aq / market.sharesAmount;
        let abps = ab / market.sharesAmount;
        setWithdrawSlider(withdrawAmountSharesRef.current.value / balances[2] * 100.0);
        setWithdrawAmountBase((withdrawAmountSharesRef.current.value * abps).toFixed(market.basePrecision));
        setWithdrawAmountQuote((withdrawAmountSharesRef.current.value * aqps).toFixed(market.quotePrecision));
    }
    function onWithdrawSliderChange()
    {
        setWithdrawSlider(withdrawSliderRef.current.value);
        let aq = market.quoteAmount / Math.pow(10, market.quotePrecision);
        let ab = market.baseAmount / Math.pow(10, market.basePrecision);
        let aqps = aq / market.sharesAmount;
        let abps = ab / market.sharesAmount;
        let amountShares = (withdrawSliderRef.current.value / 100.0 * balances[2]).toFixed(0);
        setWithdrawAmountShares(amountShares);
        setWithdrawAmountBase((amountShares * abps).toFixed(market.basePrecision));
        setWithdrawAmountQuote((amountShares * aqps).toFixed(market.quotePrecision));
        if(withdrawSliderRef.current.value === "0")
        {
            setWithdrawAmountBase("");
            setWithdrawAmountQuote("");
            setWithdrawAmountShares("");
        }
    }

    return (
        <div className={styles['row']}>
            <div className={styles['col']}>
                <div className={styles['item']}>
                    <div className={styles['th']}>Balances</div>
                    <div className={styles['balance-wrapper']}>
                        <div className={styles['balance']}>{balances[0].toFixed(market.basePrecision)} {market.baseSymbolCode}</div>
                        <div className={styles['po-balance']}>${(balances[0] * market.base_price_usd).toFixed(2)}</div>
                    </div>
                    <div className={styles['balance-wrapper']}>
                        <div className={styles['balance']}>{balances[1].toFixed(market.quotePrecision)} {market.quoteSymbolCode}</div>
                        <div className={styles['po-balance']}>${(balances[1] * market.quote_price_usd).toFixed(2)}</div>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={depositAmountBaseRef} value={depositAmountBase} onChange={onDepositAmountBaseChange} type="number" className="input-field" placeholder="Deposit Amount" name="lp-deposit-amount-base" id='lp-deposit-amount-base' required />
                        <label for="lp-deposit-amount-base" className="input-label">Deposit Amount</label>
                        <span className="input-label-ex">
                            <span>Deposit Amount</span>
                            {depositAmountBase ? <span className={styles['po-balance']}>${(depositAmountBase * market.base_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.baseSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={depositAmountQuoteRef} value={depositAmountQuote} onChange={onDepositAmountQuoteChange} type="number" className="input-field" placeholder="Deposit Amount" name="lp-deposit-amount-quote" id='lp-deposit-amount-quote' required />
                        <label for="lp-deposit-amount-quote" className="input-label">Deposit Amount</label>
                        <span className="input-label-ex">
                            <span>Deposit Amount</span>
                            {depositAmountQuote ? <span className={styles['po-balance']}>${(depositAmountQuote * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <input ref={depositSliderRef} value={depositSlider} onChange={onDepositSliderChange} type="range" min="0" max="100" defaultValue="0" list="range-detents" id='lp-deposit-range' />
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input value={depositAmountShares} type="number" className="input-field" placeholder="Amount Shares" name="lp-deposit-shares" id='lp-deposit-shares' disabled='disabled' required />
                        <label for="lp-deposit-shares" className="input-label">Amount Shares</label>
                        <span className="input-label-ex">
                            <span>Amount Shares</span>
                            {depositAmountShares ? <span className={styles['po-balance']}>${(depositAmountBase * market.base_price_usd + depositAmountQuote * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.sharesSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <button className='button button-default' onClick={() => Controller.deposit(depositAmountBase, depositAmountQuote)}>Deposit</button>
                </div>
            </div>
            <div className={styles['col']}>
                <div className={styles['item']}>
                    <div className={styles['balance-wrapper']}>
                        <div className={styles['balance']}>&nbsp;</div>
                        <div>&nbsp;</div>
                    </div>
                    <div className={styles['th']}>Shares</div>
                    <div className={styles['balance-wrapper']}>
                        <div className={styles['balance']}>{balances[2]} {market.sharesSymbolCode}</div>
                        <div className={styles['po-balance']}>${(balances[2] * (
                            market.baseAmount / Math.pow(10, market.basePrecision) * market.base_price_usd +
                            market.quoteAmount / Math.pow(10, market.quotePrecision) * market.quote_price_usd 
                        ) / market.sharesAmount).toFixed(2)}</div>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input ref={withdrawAmountSharesRef} value={withdrawAmountShares} onChange={onWithdrawAmountSharesChange} type="number" className="input-field" placeholder="Amount Shares" name="lp-withdraw-shares" id='lp-withdraw-shares' required />
                        <label for="lp-withdraw-shares" className="input-label">Amount Shares</label>
                        <span className="input-label-ex">
                            <span>Amount Shares</span>
                            {withdrawAmountShares ? <span className={styles['po-balance']}>${(withdrawAmountBase * market.base_price_usd + withdrawAmountQuote * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.sharesSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <input ref={withdrawSliderRef} value={withdrawSlider} onChange={onWithdrawSliderChange} type="range" min="0" max="100" defaultValue="0" list="range-detents" id='lp-withdraw-range' />
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input value={withdrawAmountBase} type="number" className="input-field" placeholder="Withdraw Amount" name="lp-withdraw-amount-base" id='lp-withdraw-amount-base' disabled='disabled' required />
                        <label for="lp-withdraw-amount-base" className="input-label">Withdraw Amount</label>
                        <span className="input-label-ex">
                            <span>Withdraw Amount</span>
                            {withdrawAmountBase ? <span className={styles['po-balance']}>${(withdrawAmountBase * market.base_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.baseSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <div className="input-group">
                        <input value={withdrawAmountQuote} type="number" className="input-field" placeholder="Withdraw Amount" name="lp-withdraw-amount-quote" id='lp-withdraw-amount-quote' disabled='disabled' required />
                        <label for="lp-withdraw-amount-quote" className="input-label">Withdraw Amount</label>
                        <span className="input-label-ex">
                            <span>Withdraw Amount</span>
                            {withdrawAmountQuote ? <span className={styles['po-balance']}>${(withdrawAmountQuote * market.quote_price_usd).toFixed(2)}</span> : <></>}
                        </span>
                        <span className="suffix">{market.quoteSymbolCode}</span>
                    </div>
                </div>
                <div className={styles['item']}>
                    <button className='button button-default' onClick={() => Controller.withdraw(withdrawAmountShares)}>Withdraw</button>
                </div>
            </div>
        </div>
    );
}

function EnableStopOrders()
{
    const pwRef = useRef();

    return (
        <div className={styles['row']}>
            <div className={styles['col']}>
                <Popup trigger={<button className='button button-default'>Enable Stop-Orders</button>} modal nested>
                    {(close) => (
                        <div className='modal'>
                            <div className='header'>
                                <div className='title'>Enable Stop-Orders</div>
                                <img className='close-icon' src={redCross} onClick={close} />
                            </div>
                            <div className='content'>
                                <div className='item'>Warning: Executing this transaction will...</div>
                                <div  className='item'>
                                    <ul>
                                        <li>buy the required amount of RAM for your account (if necessary)</li>
                                        <li>deploy the ZEOS 'stopcontract' to your account</li>
                                        <li>update your account's 'active' permission in order to add 'eosio.code'</li>
                                        <li>create and link a new permission 'zeosexecstop' to enable this UI to execute stop orders on your behalf</li>
                                    </ul>
                                </div>
                                <div className='item'>Keep in mind that in order for stop orders to get executed you need to keep this UI running in your browser. This is crypto: There is no middle-man between you and the markets.</div>
                                <div className='item'>All active stop orders are stored on-chain (encrypted) in your account.</div>
                                <div className='item'>Choose an encryption password:</div>
                                <div className='item'>
                                    <div className="input-group">
                                        <input autoFocus ref={pwRef} type="password" className="input-field" placeholder="Unlock-Password" name="unlock-pw" id='unlock-pw' required onKeyDown={(e) => { if(e.key === "Enter") { setShowModal(false); Controller.deployStopContract(pwRef.current.value); }}} />
                                        <label for="unlock-pw" className="input-label">Unlock-Password</label>
                                    </div>
                                </div>
                                <div className='item'>
                                    <i>You will need to enter this password each time you login to this UI.</i>
                                </div>
                                <div className='item'>
                                    <button  className='button button-default' onClick={() => { close(); Controller.deployStopContract(pwRef.current.value); }}>Deploy Stop-Contract</button>
                                    <button  className='button button-default' onClick={close}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )}
                </Popup>
            </div>
        </div>
    )
}

function UnlockStopOrders({ setShowUnlockModal })
{
    return (
        <div className={styles['row']}>
            <div className={styles['col']}>
                <button className='button button-default' onClick={() => setShowUnlockModal(true)}>
                    Unlock Stop-Contract
                </button>
            </div>
        </div>
    )
}

function Interface({ setShowUnlockModal, hasAppContract })
{
    const [market, setMarket] = useState({
        baseSymbolCode: "BASE",
        quoteSymbolCode: "QUOTE",
        sharesSymbolCode: "SHARE",
        baseAmount: 0,
        quoteAmount: 0,
        sharesAmount: 0,
        basePrecision: 0,
        quotePrecision: 0,
        priceDecimals: 0,
        platform_fee_pool: 0,
        maker_fee_pool: 0,
        platform_fee_book: 0,
        maker_fee_book: 0,
        base_price_usd: 0,
        quote_price_usd: 0,
        ftv_lp_mid: 0,
        ftv_lp_market: "BASE/QUOTE"
    });
    const [balances, setBalances] = useState([0, 0, 0]);
    const [ftvCtx, setFtvCtx] = useState(null);
    const [refBlock, setRefBlock] = useState({bn: 0, ts: 0});
    function bn2ts(bn) { return refBlock.ts + (bn - refBlock.bn) * 500; }
    function ts2bn(ts) { return Math.floor(refBlock.bn + (ts - refBlock.ts) / 500); }
    function ftvStatus(ctx, bn)
    {
        if(ctx === null)                                                                                    return 6; // nobody logged in                  => show: Fees
        if(ctx.record === null &&                                    (ctx.user_tvl <  ctx.min_tvl))         return 5; // no record, not enough tvl         => show: Fees
        if(ctx.record === null &&                                    (ctx.user_tvl >= ctx.min_tvl))         return 4; // no record, but enough tvl         => show: (y) Free Trading Volume
        if(ctx.record !== null && (bn >  ctx.record.block_height) && (ctx.user_tvl <  ctx.min_tvl))         return 3; // record, expired, not enough tvl   => show: (r) Free Trading Volume
        if(ctx.record !== null && (bn >  ctx.record.block_height) && (ctx.user_tvl >= ctx.min_tvl))         return 2; // record, expired,     enough tvl   => show: (y) Free Trading Volume
        if(ctx.record !== null && (bn <= ctx.record.block_height) && (ctx.record.free_trading_volume <= 0)) return 1; // record, not expired,     consumed => show: (r) Free Trading Volume
        if(ctx.record !== null && (bn <= ctx.record.block_height) && (ctx.record.free_trading_volume >  0)) return 0; // record, not expired, not consumed => show: (g) Free Trading Volume
        return 7;
    }
    let s = ftvStatus(ftvCtx, ts2bn(Date.now()));
    // (r) => 0, (y) => -new_ftv (g) => +ftv, other => null 
    let ftvBase  = (s === 1 ? 0 : (s == 2 || s == 4 ? -ftvCtx.user_new_ftv / market.base_price_usd  : (s === 0 ? ftvCtx.record.free_trading_volume / market.base_price_usd  : null)));
    let ftvQuote = (s === 1 ? 0 : (s == 2 || s == 4 ? -ftvCtx.user_new_ftv / market.quote_price_usd : (s === 0 ? ftvCtx.record.free_trading_volume / market.quote_price_usd : null)));
    function ftvDepositLP()
    {
        Controller.setSymbol(market.ftv_lp_market);
        setActiveTab(4);
        setTimeout(() => {
            depositAmountBaseRefRef.current.focus();
        });
    }
    const tabs = [
        { label: "Limit" },
        { label: "Market" },
        { label: "Stop" },
        { label: "Stop-Limit" },
        { label: "Liquidity Pool" },
    ];
    const [activeTab, setActiveTab] = useState(0);
    function setLimitOrder(isBuy, price)
    {
        setActiveTab(0);
        if(isBuy)
        {
            setTimeout(() => {
                limit.setPriceBuy(price);
                console.log(ftvQuote);
                let values = Controller.determineBuyTotal(price, ftvQuote === null ? 0 : Math.abs(ftvQuote));
                limit.setAmountBuy(values[0]);
                limit.setSliderBuy(values[1]);
                limit.setTotalBuy(values[2]);
            });
        }
        else
        {
            setTimeout(() => {
                limit.setPriceSell(price);
                console.log(ftvBase);
                let values = Controller.determineSellAmount(price, ftvBase === null ? 0 : Math.abs(ftvBase));
                limit.setAmountSell(values[0]);
                limit.setSliderSell(values[1]);
                limit.setTotalSell(values[2]);
            });
        }
    };
    Controller.setSetLimitOrder(setLimitOrder); // update 'Controller.setLimitOrder()' everytime it this component is re-rendered
    const [unlocked, setUnlocked] = useState(true);
    useEffect(() => {
        Controller.setOnUpdateInterfaceCallback(setMarket);
        Controller.setOnUpdateBalancesCallback(setBalances);
        Controller.setOnUpdateFtvCtxCallback(setFtvCtx);
        Controller.setOnUpdateRefBlockCallback(setRefBlock);
        //Controller.setSetLimitOrder(setLimitOrder);
        Controller.setSetUnlocked(setUnlocked);
    }, []);

    return (
        <div className={styles['tabs-container']}>
            <div className={styles['tab-bar']}>
                <div className={styles['tabs']}>
                    {tabs.map((tab, i) => <Tab key={i} label={tab.label} onClick={() => setActiveTab(i)} isActive={i === activeTab}/>)}
                </div>
                <Popup trigger={
                    s > 4 ? <div className={styles['tab']}>Fees &#9432;</div> :
                    <div className={styles['tab']}>
                        {s === 0 ? <>&#128994;</> : s === 2 || s === 4 ? <>&#128993;</> : <>&#128308;</>} Free Trading Volume &#9432;
                    </div>
                } on={['hover', 'focus']} position="bottom right" closeOnDocumentClick>
                    <div className={styles['ftv-info-container']}>
                        {s > 4 ? <></> : <>
                        <div className={styles['ftv-caption']}>
                            {s == 4 ? <><div>&#128993; Free Trading Volume (available)</div><button className='button button-default' onClick={Controller.enableFtv}>Enable</button></> : <></>}
                            {s == 3 ? <div>&#128308; Free Trading Volume (expired)</div> : <></>}
                            {s == 2 ? <><div>&#128993; Free Trading Volume (expired)</div><button className='button button-default' onClick={Controller.enableFtv}>Enable</button></> : <></>}
                            {s == 1 ? <><div>&#128308; Free Trading Volume (fully consumed)</div><div>{ftvCtx.record.block_height - ts2bn(Date.now())} blocks left</div></> : <></>}
                            {s == 0 ? <><div>&#128994; Free Trading Volume (active)</div><div>{ftvCtx.record.block_height - ts2bn(Date.now())} blocks left</div></> : <></>}
                        </div>
                        <div className={styles['ftv-description']}>
                            {s == 4 ? <div>You hold enough LP shares of <span className={styles['ftv-market']} onClick={ftvDepositLP}>{market.ftv_lp_market}</span>. Your TVL is high enough to qualify for Free Trading Volume. It's being activated automatically by simply starting to trade or by clicking the 'Enable' button above.</div> : <></>}
                            {s == 3 ? <div>Your Free Trading Volume is expired. You don't hold enough LP shares of <span className={styles['ftv-market']} onClick={ftvDepositLP}>{market.ftv_lp_market}</span> in order to qualify for new Free TradingVolume. Please add more liquidity to the LP of <span className={styles['ftv-market']} onClick={ftvDepositLP}>{market.ftv_lp_market}</span> in order to increase your TVL.</div> : <></>}
                            {s == 2 ? <div>Your Free Trading Volume is expired but you hold enough LP shares of <span className={styles['ftv-market']} onClick={ftvDepositLP}>{market.ftv_lp_market}</span> to qualify for new Free Trading Volume. It's being activated automatically by simply starting to trade or by clicking the 'Enable' button above.</div> : <></>}
                            {s == 1 ? <div>Your Free Trading Volume is fully consumed. Add more liquidity to the LP of <span className={styles['ftv-market']} onClick={ftvDepositLP}>{market.ftv_lp_market}</span> to receive higher Free Trading Volume by clicking the button above. In any case: You have to wait until your current Free Trading Volume expires before you can get a new one.</div> : <></>}
                            {s == 0 ? <div>Your Free Trading Volume is active. As long as you have Free Trading Volume left you don't pay any trading fees. Happy Trading!</div> : <></>}
                        </div>
                        </>}
                        <div className={styles['table-row']}>
                            {s > 4 ? <></> : <>
                            <table className={styles['ftv-table']}>
                                <tr><td className={styles['th']} colSpan={2}>Free Trading Volume Details</td></tr>
                                <tr><td>User</td><td>{s < 4 ? ftvCtx.record.user : '<no record>'}</td></tr>
                                <tr><td>Free Trading Volume</td>
                                    {s == 4 ? <td className='yellow'>&#8776; ${ftvCtx.user_new_ftv.toFixed(2)}</td> : <></>}
                                    {s == 3 ? <td className='red'>$0.00</td> : <></>}
                                    {s == 2 ? <td className='yellow'>&#8776; ${ftvCtx.user_new_ftv.toFixed(2)}</td> : <></>}
                                    {s == 1 ? <td className='red'>$0.00</td> : <></>}
                                    {s == 0 ? <td className='green'>${ftvCtx.record.free_trading_volume.toFixed(2)}</td> : <></>}
                                </tr>
                                <tr><td>Expiration</td><td>{s < 4 ? new Date(bn2ts(ftvCtx.record.block_height)).toString().slice(0, 25) : '<no record>'}</td></tr>
                                <tr><td>LP Shares Amount</td><td>{ftvCtx.shares_amount}</td></tr>
                                <tr><td>User TVL (min TVL)</td><td>${ftvCtx.user_tvl.toFixed(2)} (${ftvCtx.min_tvl.toFixed(2)})</td></tr>
                            </table>
                            </>}
                            {s === 0 || s === 2 || s === 4 ?
                                // modified fee table
                                <table className={styles['fee-table']}>
                                    <tr><td className={styles['th']} colSpan={2}>Order Book Fees</td></tr>
                                    <tr><td>Takers pay</td><td align='right' className='red'><del>{(market.platform_fee_book + Math.abs(market.maker_fee_book)) * 100.0}%</del></td><td align='right' className='green'><b>0.0%</b></td></tr>
                                    {market.maker_fee_book < 0.0 ? 
                                    <tr><td>Makers pay</td><td align='right' className='red'><del>{Math.abs(market.maker_fee_book) * 100.0}%</del></td><td align='right' className='green'><b>0.0%</b></td></tr> :
                                    <tr><td>Makers earn</td><td align='right' className='green'><del>{Math.abs(market.maker_fee_book) * 100.0}%</del></td><td align='right' className='green'><b>0.0%</b></td></tr>}
                                    <tr><td className={styles['th']} colSpan={2}>Liquidity Pool Fees</td></tr>
                                    <tr><td>Takers pay</td><td align='right' className='red'><del>{(market.platform_fee_pool + market.maker_fee_pool) * 100.0}%</del></td><td align='right' className='green'><b>0.0%</b></td></tr>
                                    <tr><td>Makers earn</td><td align='right' className='green'><del>{market.maker_fee_pool * 100.0}%</del></td><td align='right' className='green'><b>0.0%</b></td></tr>
                                </table>
                            :
                                // default fee table
                                <table className={styles['fee-table']}>
                                    <tr><td className={styles['th']} colSpan={2}>Order Book Fees</td></tr>
                                    <tr><td>Takers pay</td><td align='right' className='red'>{(market.platform_fee_book + Math.abs(market.maker_fee_book)) * 100.0}%</td></tr>
                                    {market.maker_fee_book < 0.0 ? 
                                    <tr><td>Makers pay</td><td align='right' className='red'>{Math.abs(market.maker_fee_book) * 100.0}%</td></tr> :
                                    <tr><td>Makers earn</td><td align='right' className='green'>{Math.abs(market.maker_fee_book) * 100.0}%</td></tr>}
                                    <tr><td className={styles['th']} colSpan={2}>Liquidity Pool Fees</td></tr>
                                    <tr><td>Takers pay</td><td align='right' className='red'>{(market.platform_fee_pool + market.maker_fee_pool) * 100.0}%</td></tr>
                                    <tr><td>Makers earn</td><td align='right' className='green'>{market.maker_fee_pool * 100.0}%</td></tr>
                                </table>
                            }
                        </div>
                        {s > 4 ? <div>
                            <div className={styles['th']}>Free Trading Volume</div>
                            <div className={styles['ftv-description']}>
                                {s  > 5 ? <div>Traders who provide liquidity for <span className={styles['ftv-market']} onClick={ftvDepositLP}>{market.ftv_lp_market}</span> qualify for Free Trading Volume!</div> : <></>}
                                {s == 5 ? <div>Secure Free Trading Volume by adding more liquidity to the LP of <span className={styles['ftv-market']} onClick={ftvDepositLP}>{market.ftv_lp_market}</span>. You need to lock up at least ${ftvCtx.min_tvl.toFixed(2)} worth of liquidity. Your current TVL is ${ftvCtx.user_tvl.toFixed(2)} ({ftvCtx.shares_amount} shares).</div> : <></>}
                            </div>
                        </div> : <></>}
                    </div>
                </Popup>
            </div>
            <div className={styles['tab-content']}>
                <datalist id="range-detents">
                    <option value="25" />
                    <option value="50" />
                    <option value="75" />
                </datalist>
                {
                    {
                        0: <Limit market={market} balances={balances} ftvBase={ftvBase} ftvQuote={ftvQuote} />,
                        1: <Market market={market} balances={balances} ftvBase={ftvBase} ftvQuote={ftvQuote} />,
                        2: unlocked ? <Stop market={market} balances={balances} ftvBase={ftvBase} ftvQuote={ftvQuote} /> : (hasAppContract ? <UnlockStopOrders setShowUnlockModal={setShowUnlockModal} /> : <EnableStopOrders />),
                        3: unlocked ? <StopLimit market={market} balances={balances} ftvBase={ftvBase} ftvQuote={ftvQuote} /> : (hasAppContract ? <UnlockStopOrders setShowUnlockModal={setShowUnlockModal} /> : <EnableStopOrders />),
                        4: <LiquidityPool market={market} balances={balances} />,
                    }[activeTab]
                }
            </div>
        </div>
    );
};

export default Interface;