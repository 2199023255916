import { useEffect, useState, useRef } from 'react';
import Controller from './Controller.js';
import styles from './Charts.module.scss';
import 'zingchart/es6';
import ZingChart from 'zingchart-react';
import 'zingchart/modules-es6/zingchart-depth.min.js';
import zoomIn from './zoom-in.png';
import zoomOut from './zoom-out.png';

function DepthChart()
{
    var cdata1 = {
        "type": "depth",
        "background-color": "#131722",
        "options": {
          "currency": "QUOTE ",
          "button-zoomout": {
            "text": "",
            "font-family": "Arial",
            "font-weight": 1,
            "font-size": 12,
            "width": 32,
            "height": 32,
            "background-image": zoomOut,
            "background-color": "transparent",
            "border-color": "transparent",
            "border-radius" : 5,
            "color": "#d1d4dc",
            "cursor": "pointer",
            "hover-state": {
                "background-color": "#2a2e39"
            },
            "active-state": {
                "background-color": "#2a2e39"
            },
            tooltip: {
                visible: false
            }
          },
          "button-zoomin": {
            "text": "",
            "font-family": "Arial",
            "font-weight": 1,
            "font-size": 12,
            "width": 32,
            "height": 32,
            "background-image": zoomIn,
            "background-color": "transparent",
            "border-color": "transparent",
            "border-radius" : 5,
            "color": "#d1d4dc",
            "cursor": "pointer",
            "hover-state": {
                "background-color": "#2a2e39"
            },
            "active-state": {
                "background-color": "#2a2e39"
            },
            tooltip: {
                visible: false
            }
          },
          "title": {
            "color": "#848e9c",
            "font-family": "Arial",
            "font-weight": 1,
            "font-size": 12,
            "text": "Market Depth"
          },
          "subtitle": {
            "visible": false
          },
          "mmp-marker": {
            "alpha": 0.75,
            "line-color": "#d1d4dc",
            "label": {
              "color": "#d1d4dc",
              "font-size": 12,
              "offset-y": 20,
              "font-family": "Arial",
              "font-weight": 1,
            }
          },
          "palette": ['#089981', '#F23645'],
          "labels": {
            "cost": "Price"
          }
        },
        "scale-x": {
          "item": {
            "font-size": 12,
            "color": "#d1d4dc",
            "font-family": "Arial",
            "font-weight": 1,
          }
        },
        "scale-y": {
          "item": {
            "font-size": 12,
            "color": "#d1d4dc",
            "font-family": "Arial",
            "font-weight": 1,
          }
        },
        "scale-y-2": {
          "item": {
            "font-size": 12,
            "color": "#d1d4dc",
            "font-family": "Arial",
            "font-weight": 1,
          }
        },
        "plot": {
          "mode": "normal"
        },
        "crosshair-x": {
          "plot-label": {
            "font-size": 12,
            "color": "#d1d4dc",
            "background-color": "#131722",
            "font-family": "Arial",
            "font-weight": 1,
          }
        },
        "series": [{
            "values": [
                [83168.4942, 0],
                [83165.2911, 24.5257],
                [83162.4432, 41.831],
                [83155.7512, 55.5769],
                [83155.2945, 67.9671],
                [83151.4232, 78.4742],
                [83151.3712, 91.5577],
                [83150.8574, 104.5983],
                [83150.4721, 123.8432],
                [83149.6144, 147.7095],
                [83148.2683, 171.1301],
                [83147.3312, 190.1485],
                [83142.4782, 210.6557],
                [83139.2812, 228.9494],
                [83139.1911, 244.5898],
                [83134.4493, 258.1534],
                [83131.9837, 278.0654],
                [83131.6223, 302.7251],
                [83130.3352, 317.3595],
                [83122.9212, 333.889],
                [83118.0342, 352.3895],
                [83117.3329, 374.7495],
                [83105.0412, 388.6047],
                [83103.0253, 404.7422],
                [83098.6836, 426.4222],
                [83095.4497, 437.743],
                [83091.9221, 461.9394],
                [83087.6823, 474.8041],
                [83083.1435, 495.8427],
                [83080.8234, 513.83],
                [83078.3357, 535.1954],
                [83076.0832, 554.3064],
                [83073.8655, 572.399],
                [83071.9923, 587.107],
                [83068.2523, 606.2238],
                [83066.6343, 623.5779],
                [83059.5455, 642.0529],
                [83057.8222, 664.9309],
                [83057.7349, 677.6618],
                [83054.5993, 702.1566]
                ],
                "text": "Sell"
            },
            {
                "values": [
                [83168.4942, 0],
                [83173.5953, 29.0944],
                [83177.5155, 60.5268],
                [83178.2274, 94.9951],
                [83178.8583, 129.6222],
                [83190.3322, 155.1349],
                [83193.0553, 179.3527],
                [83196.3542, 205.565],
                [83197.8851, 227.5875],
                [83202.0866, 247.89],
                [83202.6833, 278.4508],
                [83204.7512, 311.6391],
                [83218.5123, 343.4197],
                [83218.5655, 378.3272],
                [83222.1664, 395.7356],
                [83226.3923, 418.799],
                [83230.4766, 446.4546],
                [83232.0442, 469.4259],
                [83245.1453, 490.0896],
                [83249.8511, 523.8051],
                [83256.5532, 546.2099],
                [83257.7456, 575.9132],
                [83259.8746, 593.3904],
                [83261.8122, 621.4962],
                [83266.4694, 640.2163],
                [83270.2922, 669.7849],
                [83271.7844, 692.9558],
                [83272.8266, 718.0753],
                [83277.1333, 751.864],
                [83280.1222, 779.7172],
                [83282.6788, 809.8779],
                [83285.2123, 826.0661],
                [83293.2132, 857.981],
                [83297.19, 884.2067],
                [83311.1421, 899.814],
                [83311.5344, 927.7719],
                [83314.5662, 954.9667],
                [83322.4794, 986.9748],
                [83322.8721, 1013.3939],
                [83323.0612, 1040.1686]
                ],
                "text": "Buy"
            }
        ]
    };

    return (
        <div id="zc_depthchart_container">
            <ZingChart data={cdata1} width={798} height={500} />
        </div>
    )
}

function Shares()
{
    var myConfig = {
        "type": "pie",
        "background-color": "#131722",
        "plot": {
          "value-box": {
            connector: {
                lineColor: '#d1d4dc'
            },
            "text": "%t\n(%npv%)",
            "placement": "out",
            "offset-r": "-8",
            "font-family": "Arial",
            "font-size": 12,
            "font-weight": "normal",
            "font-color": "#d1d4dc",
            "border-color": "#d1d4dc",
          },
          tooltip: {
            text: "%t\n %v ZLPAAAA\n(%npv%)",
            'font-color': "#d1d4dc",
            'font-family': "Arial",
            'text-alpha': 1,
            'background-color': "#131722",
            //alpha: 0.7,
            'border-width': 1,
            'border-color': "#d1d4dc",
            //'line-style': "dotted",
            //'border-radius': "10px",
            padding: "10%",
            placement: "node:center" //"node:out" or "node:center"
          },
          "animation": {
            "on-legend-toggle": true, //set to true to show animation and false to turn off
            "effect": 5,
            "method": 1,
            "sequence": 1,
            "speed": 0.5
          }
        },
        "series": [{
            "values": [34],
            text: 'Alpha',
            "background-color": "#131722",
          },
          {
            "values": [30],
            text: 'Beta',
            "background-color": "#131722",
          },
          {
            "values": [15],
            text: 'Damma',
            "background-color": "#131722",
          },
          {
            "values": [14],
            text: 'Delta',
            "background-color": "#131722",
          },
          {
            "values": [5],
            text: 'Epsilon',
            "background-color": "#131722",
          }
        ]
    };

    return (
        <div className={styles['row']}>
            <ZingChart data={myConfig} width={500} height={500} />
            <div className={styles['rcol']}>
                <div className={styles['ml-col']}>
                    <div className={styles['th']}>
                        <div className={styles['th-row']}>
                            <div className={styles['th-item']}>My Liquidity</div>
                        </div>
                    </div>
                    <div className={styles['tb']}>
                        <div className={styles['tb-inner']}>
                            <div className={styles['tb-row']}>
                                <div className={styles['tb-row-item']} style={{textAlign: 'left'}}>Total Share</div>
                                <div className={styles['tb-row-item']} style={{textAlign: 'left'}}>43.5363%</div>
                            </div>
                            <div className={styles['tb-row']}>
                                <div className={styles['tb-row-item']} style={{textAlign: 'left'}}>Number of Shares</div>
                                <div className={styles['tb-row-item']} style={{textAlign: 'left'}}>645264745 ZLPAAAA</div>
                            </div>
                            <div className={styles['tb-row']}>
                                <div className={styles['tb-row-item']} style={{textAlign: 'left'}}>Amount Base</div>
                                <div className={styles['tb-row-item']} style={{textAlign: 'left'}}>75862.2853 BASE</div>
                            </div>
                            <div className={styles['tb-row']}>
                                <div className={styles['tb-row-item']} style={{textAlign: 'left'}}>Amount Quote</div>
                                <div className={styles['tb-row-item']} style={{textAlign: 'left'}}>1062.0720 QUOTE</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles['lp-col']}>
                    <div className={styles['table']}>
                        <div className={styles['th']}>
                            <div className={styles['th-row']}>
                                <div className={styles['th-item']}>Top Liquidity Providers</div>
                            </div>
                            <div className={styles['th-row']}>
                                <div className={styles['th-item']}>User</div>
                                <div className={styles['th-item']}>Total Share</div>
                            </div>
                        </div>
                        <div className={styles['tb']}>
                            <div className={styles['tb-inner']}>
                                <div className={styles['tb-row']}>
                                    <div className={styles['tb-row-item']} style={{textAlign: 'left'}}>wwwwwwwwwwww</div>
                                    <div className={styles['tb-row-item']} style={{textAlign: 'left'}}>43.5363%</div>
                                </div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                                <div>wwwwwwwwwwww</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

async function tvChartingLibraryLoaded()
{
    // This is the entry point of the Controller logic. We need to wait for the TV Chart Library
    // to be loaded before we start executing code.
    await Controller.init();
    await Controller.restore();
    let symbols = Controller.getSymbols();
    window.tvWidget = new TradingView.widget({
        symbol: symbols.length > 0 ? symbols[0].full_name : "",
        interval: '1D',                        // Default interval
        fullscreen: false,                     // Displays the chart in the fullscreen mode
        container: 'tv_chart_container',       // Reference to the attribute of the DOM element
        datafeed: Controller,
        library_path: 'charting_library/',
        enabled_features: [],
    });
    window.tvWidget.onChartReady(function() {
        // update UI if symbol of chart changes
        window.tvWidget.chart().onSymbolChanged().subscribe(null, () => {
            Controller.setNewSymbol(window.tvWidget.chart().symbol());
        }, false);
        // set default theme
        //window.tvWidget.changeTheme('light');
        window.tvWidget.changeTheme('dark');
    });
}

function Charts()
{
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "charting_library.standalone.js";
        script.async = true;
        script.onload = () => tvChartingLibraryLoaded();
        document.body.appendChild(script);
    }, []);

    const tabs = [
        { label: "Chart" },
        //{ label: "Depth" },
        //{ label: "Shares" },
    ];

    const [activeTab, setActiveTab] = useState(0);
    const tvChart = useRef(null);

    if(tvChart.current)
    {
        tvChart.current.style.display = activeTab !== 0 ? 'none' : '';
    }

    return (
        <div className={styles['tabs-container']}>
            <div className={styles['tabs']}>
                {tabs.map((tab, i) =>
                    <div className={styles['tab'] + ' ' + (i === activeTab ? styles['active'] : '')} onClick={() => setActiveTab(i)}>
                        {tab.label}
                    </div>
                )}
            </div>
            <div className={styles['tab-content']}>
                <div id="tv_chart_container" ref={tvChart}></div>
                {
                    {
                        0: <></>,
                        1: <DepthChart />,
                        2: <Shares />
                    }[activeTab]
                }
            </div>
        </div>
    );
};

export default Charts;