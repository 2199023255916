import { useEffect, useState } from 'react';
import styles from './TopBar.module.scss';
import Controller from './Controller';

function TopBar()
{
    const [topBar, setTopBar] = useState({
        price: "0.0",
        price_usd: "0.0",
        green: true,
        change: 0.0,
        high: "0.0",
        low: "0.0",
        volume: "0.0",
        baseSymbolCode: "BASE",
        quoteSymbolCode: "QUOTE",
    });
    useEffect(() => {
        Controller.setOnUpdateTopBarCallback(setTopBar);
    }, []);

    return (
        <div className={styles['row']}>
            <div className={styles['item']}>
                <div style={{fontSize: '1.5em'}}>{topBar.baseSymbolCode}/{topBar.quoteSymbolCode}</div>
            </div>
            <div className={styles['item']}>
                <div className={styles['th']}>Last Price</div>
                <div className={styles['tb']}>
                    <div className={styles['balance-wrapper']}>
                        <div className={(topBar.green ? 'green' : 'red')}>{topBar.price + ' ' + topBar.quoteSymbolCode + (topBar.green ? '\u{25B2}' : '\u{25BC}')}</div>
                        <div className={styles['po-balance']}>${topBar.price_usd}</div>
                    </div>
                </div>
            </div>
            <div className={styles['item']}>
                <div className={styles['th']}>24h Change</div>
                <div className={styles['tb'] + (topBar.change > 0 ? ' green' : (topBar.change < 0 ? ' red' : ''))}>{topBar.change.toFixed(2) + '%' + (topBar.change > 0 ? '\u{25B2}' : (topBar.change < 0 ? '\u{25BC}' : ''))}</div>
            </div>
            <div className={styles['item']}>
                <div className={styles['th']}>24h High</div>
                <div className={styles['tb']}>{topBar.high + ' ' + topBar.quoteSymbolCode}</div>
            </div>
            <div className={styles['item']}>
                <div className={styles['th']}>24h Low</div>
                <div className={styles['tb']}>{topBar.low + ' ' + topBar.quoteSymbolCode}</div>
            </div>
            <div className={styles['item']}>
                <div className={styles['th']}>24h Volume</div>
                <div className={styles['tb']}>{topBar.volume + ' ' + topBar.baseSymbolCode}</div>
            </div>
        </div>
    )
}

export default TopBar;