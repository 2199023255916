import { useEffect, useState } from 'react';
import styles from './Deals.module.scss';
import Controller from './Controller';

function Deals()
{
    const [deals, setDeals] = useState({
        rows: [],
        baseSymbolCode: "BASE",
        quoteSymbolCode: "QUOTE",
    });
    useEffect(() => {
        Controller.setOnUpdateDealsCallback(setDeals);
    }, []);

    return (
        <div className={styles['table']}>
            <div className={styles['th']}>
                <div className={styles['th-row']}>
                    <div className={styles['th-item']} style={{textAlign: 'center'}}>Latest Deals</div>
                </div>
                <div className={styles['th-row']}>
                    <div className={styles['th-item-lp']} style={{textAlign: 'left'}}>LP</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Price ({deals.quoteSymbolCode})</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Volume ({deals.baseSymbolCode})</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Time</div>
                </div>
            </div>
            <div className={styles['tb']}>
                <div className={styles['tb-inner']}>
                    {
                        deals.rows.map(d =>
                            <div className={styles['tb-row']}>
                                <div className={styles['tb-row-item-lp'] + (d[0] ? ' green' : ' red')} style={{textAlign: 'left'}}>{d[1]}</div>
                                <div className={styles['tb-row-item'] + (d[0] ? ' green' : ' red')} style={{textAlign: 'right'}}>{d[2]}</div>
                                <div className={styles['tb-row-item'] + (d[0] ? ' green' : ' red')} style={{textAlign: 'right'}}>{d[3]}</div>
                                <div className={styles['tb-row-item']} style={{textAlign: 'right'}} title={new Date(d[4]).toLocaleString('en-US', { hour12: false })}>{new Date(d[4]).toLocaleTimeString('en-US', { hour12: false })}</div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Deals;