import { useEffect, useState, useRef } from 'react';
import styles from './Orders.module.scss';
import Controller from './Controller';
import redCross from './red-cross.png'

function Tab({ label, onClick, isActive })
{
    return (
        <div className={styles['tab'] + ' ' + (isActive ? styles['active'] : '')} onClick={onClick}>
            {label}
        </div>
    );
}

function OpenOrders({ orders })
{
    return (
        <div className={styles['table']}>
            <div className={styles['th']}>
                <div className={styles['th-row']}>
                    <div className={styles['th-item']} style={{textAlign: 'left'}}>Pair</div>
                    <div className={styles['th-item']} style={{textAlign: 'left'}}>Type</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Stop-Price</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Limit-Price</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Amount Open</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Amount Dealt</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Progress</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Time</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Cancel</div>
                </div>
            </div>
            <div className={styles['tb']}>
                <div className={styles['tb-inner']}>
                    {
                        orders.map(o =>
                            <div className={styles['tb-row']}>
                                <div className={styles['tb-row-item']} style={{textAlign: 'left'}}>{o.pair}</div>
                                <div className={styles['tb-row-item'] + (o.isBuy ? ' green' : ' red')} style={{textAlign: 'left'}}>{o.type}</div>
                                <div className={styles['tb-row-item']} style={{textAlign: 'right'}}>{o.stop_price}</div>
                                <div className={styles['tb-row-item']} style={{textAlign: 'right'}}>{o.limit_price}</div>
                                <div className={styles['tb-row-item']} style={{textAlign: 'right'}} title={(o.isBuy ? o.base_amount_open : o.quote_amount_open) + "\nFee earnings: " + o.fee_earned}>{o.isBuy ? o.quote_amount_open : o.base_amount_open}</div>
                                <div className={styles['tb-row-item']} style={{textAlign: 'right'}} title={(o.isBuy ? o.quote_amount_dealt : o.base_amount_dealt) + "\nFee earnings: " + o.fee_earned}>{o.isBuy ? o.base_amount_dealt : o.quote_amount_dealt}</div>
                                <div className={styles['tb-row-item']} style={{textAlign: 'right'}}>
                                    {Object.hasOwn(o, 'progress') ? // progress bar only for limit orders
                                        <div
                                            style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: '0.2em'}}
                                            title={o.base_amount_dealt + ' (' + o.quote_amount_dealt + ')/\n' + o.base_initial_amount_open + ' (' + o.quote_initial_amount_open + ')'}
                                        >
                                            <label for={o.id + "-progress"}>{o.progress.toFixed(2)}%</label>
                                            <progress id={o.id + "-progress"} value={o.progress} max={100.0}></progress>
                                        </div> :
                                    <>-</>}
                                </div>
                                <div className={styles['tb-row-item']} style={{textAlign: 'right'}}>{new Date(o.timestamp).toLocaleString('en-US', { hour12: false })}</div>
                                <div className={styles['tb-row-item']} style={{textAlign: 'right'}}><a onClick={() => Controller.cancelOrder(o.mid, o.id, o.isBuy, o.stop_price !== '-')}><img className={styles['icon']} src={redCross} /></a></div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

function TradeHistory({ data, curPage, numPages, numTradesPerPage })
{
    return (
        <div className={styles['table']}>
            <div className={styles['th']}>
                <div className={styles['th-row']}>
                    <div className={styles['th-item']} style={{textAlign: 'left'}}>Pair</div>
                    <div className={styles['th-item']} style={{textAlign: 'left'}}>Type</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Price</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Amount</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Time</div>
                </div>
            </div>
            <div className={styles['tb']}>
                <div className={styles['tb-inner']}>
                    {
                        data.toReversed().map(t =>
                            <div className={styles['tb-row']}>
                                <div className={styles['tb-row-item']} style={{textAlign: 'left'}}>{t.pair}</div>
                                <div className={styles['tb-row-item'] + (t.isBuy ? ' green' : ' red')} style={{textAlign: 'left'}}>{t.type} {t.isBuy ? 'BUY' : 'SELL'}</div>
                                <div className={styles['tb-row-item']} style={{textAlign: 'right'}}>{t.price}</div>
                                <div className={styles['tb-row-item']} style={{textAlign: 'right'}}>{t.amount}</div>
                                <div className={styles['tb-row-item']} style={{textAlign: 'right'}}>{t.time}</div>
                            </div>
                        )
                    }
                </div>
            </div>
            {numPages > 1 ?
                <div className={styles['paging']}>
                    <div className={styles['paging-item']} onClick={() => Controller.setCurTradesTablePage(0)}>{'<<'}</div>
                    <div className={styles['paging-item']} onClick={() => Controller.setCurTradesTablePage(curPage-1)}>{'<'}</div>
                    <div>{curPage+1}/{numPages}</div>
                    <div className={styles['paging-item']} onClick={() => Controller.setCurTradesTablePage(curPage+1)}>{'>'}</div>
                    <div className={styles['paging-item']} onClick={() => Controller.setCurTradesTablePage(numPages-1)}>{'>>'}</div>
                </div>
            : <></>}
        </div>
    )
}

function Orders()
{
    const tabs = [
        { label: "Open Orders" },
        { label: "Trade History" },
    ];
    const [activeTab, setActiveTab] = useState(0);
    const [orders, setOrders] = useState([]);
    const [tradesTable, setTradesTable] = useState({ data: [], curPage: 0, numPages: 0, numTradesPerPage: 0 });
    useEffect(() => {
        Controller.setOnUpdateOrdersCallback(setOrders);
        Controller.setOnUpdateTradesTableCallback(setTradesTable);
    }, []);
 
    return (
        <div className={styles['tabs-container']}>
            <div className={styles['tabs']}>
                {tabs.map((tab, i) => <Tab key={i} label={tab.label} onClick={() => setActiveTab(i)} isActive={i === activeTab}/>)}
            </div>
            <div className={styles['tab-content']}>
                {
                    {
                        0: <OpenOrders orders={orders} />,
                        1: <TradeHistory data={tradesTable.data} curPage={tradesTable.curPage} numPages={tradesTable.numPages} numTradesPerPage={tradesTable.numTradesPerPage} />
                    }[activeTab]
                }
            </div>
        </div>
    );
};

export default Orders;