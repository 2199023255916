import { useEffect, useState } from 'react';
import styles from './Pool.module.scss';
import Controller from './Controller';

function Pool({ hoverPrice })
{
    const [pool, setPool] = useState({
        price: "0.0",
        price_usd: "0.0",
        base: "0.0",
        baseSymbolCode: "BASE",
        depthBarAsk: "0%",
        quote: "0.0",
        quoteSymbolCode: "QUOTE",
        depthBarBid: "0%",
        green: true
    });
    useEffect(() => {
        Controller.setOnUpdatePoolCallback(setPool);
    }, []);

    return (
        <div className={styles['table']}>
            <div className={styles['th']}>
                <div className={styles['th-row']}>
                    <div className={styles['th-item']} style={{textAlign: 'center'}}>Pool</div>
                </div>
                <div className={styles['th-row']}>
                    <div className={styles['th-item']} style={{textAlign: 'left'}}>Price ({pool.quoteSymbolCode})</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Depth</div>
                </div>
            </div>
            <div className={styles['tb']}>
                <div className={styles['tb-item']} style={{textAlign: 'left'}}>
                    <div className={styles['balance'] + (pool.green ? ' green' : ' red')}>{pool.price + (pool.green ? '\u{25B2}' : '\u{25BC}')}</div>
                    <div className={styles['po-balance']}>${pool.price_usd}</div>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"><path d="M5 13.47l1.41-1.41 5.1 5.1V3h1.99v14.15l5.09-5.09L20 13.47l-7.5 7.5-7.5-7.5z" fill="red"></path></svg> */}
                    {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" style={{transform: 'rotate(180deg)'}}><path d="M5 13.47l1.41-1.41 5.1 5.1V3h1.99v14.15l5.09-5.09L20 13.47l-7.5 7.5-7.5-7.5z" fill="green"></path></svg> */}
                </div>
                <div className={styles['tb-item']} style={{textAlign: 'right'}}>
                    <div style={{position: 'relative', backgroundColor: (parseFloat(hoverPrice) > parseFloat(pool.price) ? '#4C5C8840' : '')}}>{pool.base + ' ' + pool.baseSymbolCode}<i className={styles['depth-bar-ask']} style={{width: pool.depthBarAsk}}></i></div>
                    <div style={{position: 'relative', backgroundColor: (parseFloat(hoverPrice) < parseFloat(pool.price) ? '#4C5C8840' : '')}}>{pool.quote + ' ' + pool.quoteSymbolCode}<i className={styles['depth-bar-bid']} style={{width: pool.depthBarBid}}></i></div>
                </div>
            </div>
        </div>
    )
}

export default Pool;