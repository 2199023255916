import { useEffect, useState } from 'react';
import styles from './Ticker.module.scss';
import Controller from './Controller';

function Ticker()
{
    const [ticker, setTicker] = useState([]);
    useEffect(() => {
        Controller.setOnUpdateTickerCallback(setTicker);
    }, []);

    return (
        <div className={styles['table']}>
            <div className={styles['th']}>
                <div className={styles['th-row']}>
                    <div className={styles['th-item']} style={{textAlign: 'center'}}>Markets</div>
                </div>
                <div className={styles['th-row']}>
                    <div className={styles['th-item']} style={{textAlign: 'left'}}>Pair</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>Price</div>
                    <div className={styles['th-item']} style={{textAlign: 'right'}}>24h Change</div>
                </div>
            </div>
            <div className={styles['tb']}>
                <div className={styles['tb-inner']}>
                    {
                        ticker.map(t =>
                            <div className={styles['tb-row']} onClick={() => Controller.setSymbol(t.symbol)}>
                                <div className={styles['tb-row-item']} style={{textAlign: 'left'}}>{t.symbol}</div>
                                <div className={styles['tb-row-item'] + (t.green ? ' green' : ' red')} style={{textAlign: 'right'}}>{t.price + (t.green ? '\u{25B2}' : '\u{25BC}')}</div>
                                <div className={styles['tb-row-item'] + (t.change === null || t.change > 0 ? ' green' : (t.change < 0 ? ' red' : ''))} style={{textAlign: 'right'}}>{(t.change === null ? Infinity : t.change.toFixed(2)) + '%' + (t.change > 0 ? '\u{25B2}' : (t.change < 0 ? '\u{25BC}' : ''))}</div>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default Ticker;